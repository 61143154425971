<template>
  <div>
    <ul class="aboutList">
      <li><router-link to="/moAbout#pro1">合友概况</router-link></li>
      <li><router-link to="/moAbout#pro3">合友事记</router-link></li>
      <li @click="showVideo"><div> </div></li>
    </ul>
    <div class="videoBigBox" v-if="showOrNot">
      <div class="videoBox">
<!--        <p class="showSmall" v-if="showSmallOrNot">按ESC退出播放</p>-->
        <a class="close fr" @click="closeMask" title="或按ESC退出视频"></a>
        <video-player class="video-player vjs-custom-skin"
                      ref="videoPlayer"
                      :playsinline="true"
                      :options="playerOptions"
        >
        </video-player>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MoIndexAbout",
  data(){
    return{
      showOrNot:'',
      playerOptions: {
        //播放速度
        playbackRates: [0.5, 1.0, 1.5, 2.0],
        //如果true,浏览器准备好时开始回放。
        autoplay: true,
        // 默认情况下将会消除任何音频。
        muted: false,
        // 导致视频一结束就重新开始。
        loop: false,
        // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        preload: 'auto',
        language: 'zh-CN',
        // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        aspectRatio: '16:9',
        // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        fluid: true,
        sources: [{
          //类型
          type: "video/mp4",
          //url地址
          src: require("../../../assets/eventsVideo.mp4")
        }],
        //你的封面地址
        poster: '',
        //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        notSupportedMessage: '此视频暂无法播放，请稍后再试',
        controls:true,
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          //全屏按钮
          fullscreenToggle: true,
          currentTimeDisplay: true, // 当前时间
          volumeControl: true, // 声音控制键
          playToggle: true, // 暂停和播放键
          // progressControl: true, // 进度条

        }
      },
      showSmallOrNot:''
    }
  },
  methods:{
    showVideo(){
      this.showOrNot = true
      this.showSmallOrNot = true
      setTimeout(() => {
        this.showSmallOrNot=false;
      },3000);
    },
    closeMask() {
      this.showOrNot = false
    },
  }
}
</script>

<style scoped>
.aboutList{
  width: 32.5rem;
  margin: 0 auto;
}
.aboutList li{
  width: 32.5rem;
  height: 32.5rem;
  margin-bottom: 2rem;
}
.aboutList li a{
  background-size: cover;
  font-size: 2.8rem;
  width: 100%;
  display: block;
  text-align: center;
  line-height: 32.5rem;
  font-weight: 400;
  color: #fff;
}
.aboutList li:first-child{
  background: url("./image/aboutBg1.png") center center no-repeat;
}
.aboutList li:nth-child(2){
  background: url("./image/aboutBg2.png") center center no-repeat;
}
.aboutList li:nth-child(3){
  background: url("./image/aboutBg3.png") center center no-repeat;
}


.showSmall{
  width: 20rem;
  line-height: 3.2rem;
  background-color: rgba(255,255,255,0.2);
  z-index: 1000;
  position: absolute;
  top: -6rem;
  left: calc(50% - 10rem);
  border-radius: 0.2rem;
  color: #ccc;
}

.close{
  display: block;
  width: 2.6rem;
  height: 2.6rem;
  background: url("image/closeDialog.svg") center center no-repeat;
  z-index: 1000;
  position: absolute;
  right: 0;
  top: -6rem;
  transition: 0.5s;
}
.videoBigBox{
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,1);
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
}

.videoBox{
  position: absolute;
  top: calc(50% - 20rem);
  left: 2.4rem;
  width: 32.7rem;
}

.vjs-custom-skin /deep/>.video-js {
  pointer-events: all!important;
}

.vjs-custom-skin /deep/.vjs-volume-panel{
  display: none;
}
</style>