<template>
  <div class="bigBox boxSizing">
    <div class="newsBox">
      <div class="newsTitleBox">
        <p class="newsTitle">{{ currentRec.title }}</p>
        <p class="newsDate">{{ currentRec.createTime }}</p>
      </div>
      <div class="textBox">
        <p class="textTitle">岗位描述</p>
        <p ref="contentText" class="text" v-html="currentRec.description"></p>
      </div>
      <div class="textBox">
        <p class="textTitle">岗位职责</p>
        <p ref="contentText" class="text" v-html="currentRec.requirements"></p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "MoRecruitInner",
  data(){
    return{
      recList:'',
      currentRec:''
    }
  },
  created() {
    this.getRecList()
  },
  methods:{
    getRecList(){
      axios.get('/recruit/list').then(
          response => {
            // console.log(response.data)
            this.recList = response.data
            this.currentRec = response.data[this.$route.query.index]
            console.log(this.currentRec)
            // this.newsList = this.newsListBig.slice(0,5)
          },
          error => {
            console.log(error.message)
          }
      )
    },
  },
}
</script>

<style scoped>
.bigBox{
  width: 100%;
  padding: 10.4rem 2.4rem 2.4rem 2.4rem;
}
.newsTitle{
  font-size: 2.4rem;
  line-height: 3.6rem;
}
.newsDate{
  font-size: 1.4rem;
  line-height: 5.2rem;
  color: #999;
}
.newsTitleBox{
  border-bottom: 0.1rem solid #eaeaea;
}
.textBox{
  margin-top: 1.6rem;
}
.textTitle{
  font-size: 1.8rem;
  line-height: 300%;
  color: #333;
  font-weight: 500;
}
.text /deep/ .MsoNormal{
  font-size: 1.6rem!important;
  line-height: 3.2rem!important;
  color: #333!important;
  text-align: justify!important;
}
</style>