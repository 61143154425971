<template>
  <div class="boxSizing">
    <HeadEr></HeadEr>
    <div class="navBg"></div>
    <div class="udNavBg">
      <p>合友新闻 - {{ info.title }}</p>
    </div>
    <div class="newsBox">
      <div class="newsTitleBox">
        <p class="newsTitle">{{ info.title }}</p>
        <p class="newsDate">{{ info.date }}</p>
      </div>
      <div class="textBox">
        <p class="smallTitle">{{ title }}</p>
        <p ref="contentText" class="text" v-html="contentText"></p>
      </div>
    </div>
  </div>
</template>

<script>
// import HeadEr2 from "@/components/Header2/Head2";
import HeadEr from "@/components/PC/Header/Head";
import axios from "axios";
import global from "@/config/global";


export default {
  name: "NewsInner",
  components: {HeadEr},
  data() {
    return {
      title: '',
      date: '',
      info: '',
      baseUrl: global.BASE_URL_2,
      contentText: ''
    }
  },
  methods: {
    getNewsInfo() {
      axios.get(`/journalism/queryNewsInfo?id=${this.$route.query.id}`).then(
          response => {
            this.info = response.data
            // console.log(response.data)
            let contentText = response.data.content;  //从response获取HTML的数据
            // let srcReg = /<\/?(img)[^>]*>/gi;
            // if(contentText){
            //   contentText = contentText.replace(srcReg,"src='"+this.baseUrl+"$2"+"'");
            //   console.log(contentText)
            //   this.html = contentText;
            // }
            contentText= contentText.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi, (match, p1) => {

              return  `<img style="max-width: 100%;margin: 24px auto;" src='${this.baseUrl+p1}' />`
            })
            this.contentText = contentText
            // console.log(contentText)
          },
          error => {
            console.log(error.message)
          }
      )
    },

  },
  mounted() {
    // console.log(this.$route.query.id)
    this.getNewsInfo(
    )
    // console.log(this.$route.query.text)
    // this.title= this.$route.query.title
    // this.date= this.$route.query.date
  }
}
</script>

<style scoped>
.navBg {
  height: 80px;
  width: 100%;
  background-color: #fff;
}

.udNavBg {
  width: 100%;
  background-color: #fbfbfb;
}

.udNavBg p {
  width: 1200px;
  margin: 0 auto;
  text-align: left;
  line-height: 80px;
  font-size: 16px;
  color: #333;
}

.newsBox {
  width: 100%;
  background-color: #fff;
}
.newsTitleBox {
  width: 840px;
  margin: 0 auto;
  border-bottom: 1px solid #d8d8d8;
}

.newsTitle {
  font-size: 44px;
  font-weight: lighter;
  line-height: 66px;
  padding-top: 52px;
  padding-bottom: 16px;
}

.newsDate {
  font-size: 16px;
  line-height: 40px;
  color: #808080;
}

.textBox {
  padding-top: 32px;
  width: 840px;
  margin: 0 auto;
}

.smallTitle {
  font-size: 24px;
  line-height: 96px;
  color: #808080;
  text-align: center;
}

.text {
  font-size: 16px;
  line-height: 32px;
  color: #808080;
  padding-bottom: 72px;
}
.text img{
  max-width: 100% !important;
}

.text /deep/ .MsoNormal{
  font-size: 14px!important;
  line-height: 32px!important;
  color: #808080!important;
}
</style>