<template>
  <div class="clear">
    <div class="navBox boxSizing" :class="navFixed === true ?'navBoxNormal' : ''|| newNav === true?'newStyle' : ''" id="navBox">
      <router-link class="logo" :class="navFixed === true?'logoNormal':''|| newNav === true?'newLogo' : ''" :to="nav[0].url"></router-link>
      <div class="nav" :class="navFixed === true?'navNormal':''|| newNav === true?'newNav' : ''" id="navBtn" @click="showNav"></div>
      <div v-if="backArr === true" class="backArr" @click="backToBefore"></div>
    </div>
    <div id="navTrue" class="boxSizing">
      <div class="close" @click="hideNav"></div>
      <ul class="boxSizing">
        <li v-for="item in nav" :key="item.id" @click="hideNav">
          <router-link :to="{path:item.url,hash:item.hash}">{{item.name}}</router-link>
        </li>
      </ul>
    </div>
    <div id="mask" @click="hideNav"></div>
  </div>
</template>

<script>
export default {
  name: "MobileHead",
  data(){
    return{
      nav:[
        {id:1,name:'首页',url:'/mobile'},
        {id:2,name:'关于',url:'/MobileAbout'},
        {id:5,name:'事记',url:'/MobileAbout',hash:'#pro2'},
        {id:3,name:'产品',url:'/MobileAbout',hash:'#pro3'},
        {id:4,name:'新闻',url:'/moNews'},
        {id:6,name:'资质',url:'/MoQualification'},
        {id:7,name:'联系我们',url:'/MobileAbout',hash:'#pro5'},
        {id:8,name:'加入我们',url:'/MoRecruit'}
      ],
      navFixed:false,
      newNav:false,
      backArr:false
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true);
  },
  methods: {
    showNav() {
      let nav = document.getElementById("navTrue")
      nav.style.left = '0'
      let mask = document.getElementById("mask")
      mask.style.right='0'
      let navBtn = document.getElementById("navBtn")
      navBtn.style.transform='rotate(90deg)'
    },
    hideNav(){
      let nav = document.getElementById("navTrue")
      nav.style.left = '-26rem'
      let mask = document.getElementById("mask")
      mask.style.right='-100%'
      let navBtn = document.getElementById("navBtn")
      navBtn.style.transform='rotate(0deg)'
    },
    handleScroll(){
      // 页面滚动距顶部距离
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop ||
          document.body.scrollTop
      let offsetTop = document.querySelector(".navBox").offsetTop
      let scroll = scrollTop - this.i;
      this.i = scrollTop;
      if(scroll<0){
        let navBox = document.getElementById("navBox")
        navBox.style.top = '0'
      }else{
        let navBox = document.getElementById("navBox")
        navBox.style.top = '-7.4rem'
      }

      if (scrollTop > (offsetTop + 100)) {
        this.navFixed = true
        // console.log(this.navFixed)
      }else{
        this.navFixed = false
      }
    },
    backToBefore(){
      this.$router.back()
    }
  },
  watch:{
    '$route.path':function(newValue){
      if(newValue === '/moNewsInner'||newValue === '/moRecruitInner'){
        this.newNav = true
        this.backArr = true
      }else{
        this.newNav = false
        this.backArr = false
      }
    }
  },
  metaInfo() {
    return {
      meta: [
        {
          name: 'viewport',
          content: 'width=device-width,user-scalable = no,initial-scale = 1.0,maximum-scale = 1.0,minimum-scale =1.0'
        }
      ]
    }
  },
}
</script>

<style scoped>
.navBox {
  position: fixed;
  top: 0;
  left: 0;
  width: 37.5rem;
  padding: 2rem 0;
  z-index: 2;
  transition: 0.3s;
}

.logo {
  display: block;
  background: url("./image/logo.svg") center center no-repeat;
  background-size: cover;
  width: 10.2rem;
  height: 3.4rem;
  margin: 0 auto;
}

.logoNormal,.newLogo{
  background: url("./image/logoNormal.svg") center center no-repeat;
  background-size: cover;
  width: 10.2rem;
  height: 3.4rem;
  margin: 0 auto;
}

.nav {
  background: url("./image/navBtn.svg") center center no-repeat;
  background-size: cover;
  width: 1.6rem;
  height: 1.4rem;
  position: absolute;
  right: 2.4rem;
  top: 2.8rem;
  transition: 0.3s;
}

.navNormal,.newNav{
  background: url("./image/navBtnNormal.svg") center center no-repeat;
  background-size: cover;
  width: 1.6rem;
  height: 1.4rem;
  position: absolute;
  right: 2.4rem;
  top: 2.8rem;
  transition: 0.3s;
}

#mask{
  background-color: #333;
  transition: 0.3s;
  opacity: 0.3;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 2;
  right: -100%;
  top: 0;
}
#navTrue {
  transition: 0.3s;
  background-color: #fff;
  width: 26rem;
  height: 100%;
  position: fixed;
  z-index: 3;
  left: -26rem;
  top: 0;
  padding-top: 9rem;
}
#navTrue ul{
  width: 20.2rem;
  padding-left: 6rem;
}
#navTrue ul li a{
  font-size: 1.6rem;
  color: #333;
  line-height: 6.2rem;
  font-weight: normal;
}
.close{
  width: 1.485rem;
  height: 1.485rem;
  background:url("./image/closeNav.svg") center center no-repeat;
  position: absolute;
  top:3rem;
  left: 4rem;
}

.navBoxNormal,.newStyle{
  background-color: #fff;
}
.backArr{
  position: absolute;
  left: 2.4rem;
  top: 2.8rem;
  background: url("./image/backArr.svg") center center no-repeat;
  width: 1.3rem;
  height: 2rem;
}
</style>