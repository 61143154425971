<template>
  <div>
    <div class="moBannerOut moBannerInside boxSizing">
      <p class="moBannerTitle moTitle">合友资质</p>
    </div>
    <ul class="quaList clear boxSizing">
      <li v-for="(item,index) in quaInfo" :key="item.id" class="fl">
        <img class="listImg" :src="baseUrl+item.img" @click="showBig(index)"/>
        <p class="listTitle" v-html="item.description"></p>
      </li>
    </ul>
    <div class="bigPicContainer boxSizing" v-if="showTheBig" @touchmove.prevent @mousewheel.prevent>
      <div class="close" @click="closeBig"></div>
      <ul class="clear bigPicList" :style="marginLeft">
<!--        <li v-for="(item,index) in quaInfo" :key="item.id ">-->
<!--          <img :src="baseUrl+item[index].img"/>-->
<!--        </li>-->
        <li v-for="item in quaInfo" :key="item.id" class="fl boxSizing">
          <img :src="baseUrl+item.img"/>
          <div class="clear pBox">
            <div class="leftArr fl" @click="toLeft"></div>
            <p v-html="item.description" class="listTitle listTitleInner fl"></p>
            <div class="rightArr fr" @click="toRight"></div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import global from "@/config/global";
import axios from "axios";

export default {
  name: "MoQualification",
  data(){
    return {
      quaInfo:'',
      baseUrl:global.BASE_URL,
      showTheBig:false,
      current:'',
      marginLeft:''
    }
  },
  mounted() {
    this.getQuaInfo()
  },
  methods:{
    getQuaInfo() {
      axios.get('/qualification/list').then(
          response => {
            // console.log(response.data)
            this.quaInfo = response.data
          },
          error => {
            console.log(error.message)
          }
      )
    },
    showBig(index){
      this.showTheBig = true
      this.current = index
      this.marginLeft = 'margin-left:'+ -this.current * 37.5 + 'rem'
      console.log(this.current,this.marginLeft)
    },
    closeBig(){
      this.showTheBig = false
    },
    toLeft(){
      if(this.current>0){
        this.current --
        this.marginLeft = 'margin-left:'+ -this.current * 37.5 + 'rem'
      }
    },
    toRight(){
      if(this.current < (this.quaInfo.length -1)){
        this.current ++
        this.marginLeft = 'margin-left:'+ -this.current * 37.5 + 'rem'
      }
    }
  }
}
</script>

<style scoped>
.moBannerInside{
  background-image: url("./image/quaBg.jpg");
  margin-bottom: 3rem;
}
.moBannerTitle{
  margin: 0;
  text-align: left;
  padding-left: 2.4rem;
  color: #fff;
  font-size: 2.8rem;
  font-weight: lighter;
}
/*列表*/
.quaList{
  width: 37.5rem;
  padding: 0 2.4rem;
  /*overflow: hidden;*/
}
.quaList li{
  width: 100%;
  margin-bottom: 3rem;
}
.quaList li img{
  display: block;
  max-width:24rem;
  max-height: 11rem;
  margin: 0 auto;
}
.listTitle{
  display: block;
  font-size: 1.4rem;
  text-align: center;
  width: 25rem;
  margin: 2rem auto 0 auto;
}
.bigPicList{
  transition: 0.5s;
}
.pBox{
  width: 32.7rem;
  margin: 0 auto;
}
.leftArr,.rightArr{
  width: 3.85rem;
  height: 6rem;
}
.leftArr{
  background:url("./image/arrLeft.svg") center center no-repeat;
}
.rightArr{
  background:url("./image/arrRight.svg") center center no-repeat;
}
.bigPicContainer{
  width: 37.5rem;
  height: 100vh;
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  overflow-x: auto;
  background-color: #fff;
  padding-top: 10rem;
}
.bigPicContainer ul{
  width: 2000rem;
  height: calc(100vh - 10rem);
}
.bigPicContainer ul li{
  width: 37.5rem;
  padding: 0 2.4rem;
  height: calc(100vh - 10rem);
}
.bigPicContainer ul li img{
  display: block;
  max-width:100%;
  max-height:60vh;
  margin: 0 auto;
}
.bigPicContainer::-webkit-scrollbar{
  display: none;
}

.close{
  display: block;
  width: 26px;
  height: 26px;
  background: url("image/closeDialog.svg") center center no-repeat;
  z-index: 1000;
  position: absolute;
  right: 2.4rem;
  top: 2.4rem;
  transition: 0.5s;
}
</style>