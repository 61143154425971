<template>
  <div class="bigBox">
    <div class="banner">
      <div class="bannerMain">
        <p>加入我们</p>
      </div>
    </div>
    <div class="leftUlBox" id="leftUlBox" :class="leftFixed === true&&isBtm ===false?'leftFixed':(leftFixed === false&&isBtm ===true)?'isFixBtm':''">
      <div style="width: 1200px;margin: 0 auto;" class="clear">
        <ul class="leftUl fl boxSizing" ref="leftUl">
          <li class="leftLi boxSizing" :class="{'isActive': index===currentIndex}" @click="handleLeft(index)" v-for="(item,index) in recList" :key="item.id">
            <p class="recTitle">{{item.title}}</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="mainBox">
      <div class="mainContainer clear">
        <div class="leftUlBg fl"></div>
        <input type="button" @click="toMail" value="申请职位" class="offerBtn" :data-clipboard-text="emailAdd"/>
        <ul class="rightList fr" id="rightList">
          <li :id="'id'+index" v-for="(item,index) in recList" :key="item.id" class="clear boxSizing">
            <p class="rightBigTitle">{{item.title}}</p>
            <p class="rightSmallTitle">{{item.subTitle}}</p>
            <div class="rightP1 fl boxSizing">
              <p class="rightTitle">岗位描述</p>
              <p v-html="item.description" ></p>
            </div>
            <div class="rightP2 fl boxSizing">
              <p class="rightTitle">岗位描述</p>
              <p v-html="item.requirements"></p>
            </div>
          </li>
        </ul>
        <!--        <input type="button" onclick="location.href='mailto:1753546998@qq.com'" value="申请职位" class="offerBtn"/>-->
      </div>
    </div>

  </div>

</template>

<script>
import axios from "axios";
import ClipboardJS from 'clipboard'

export default {
  name: "RecruIt",
  data() {
    return {
      currentIndex: 0,
      listBoxState: true,//点击导航栏时，暂时停止监听页面滚动
      leftFixed:false,
      recList:[],
      isBtm:false,
      emailAdd:''
    };
  },
  created() {

  },
  mounted() {
    let timeId;
    window.addEventListener('scroll', () => {
      // 页面滚动停止100毫秒后才会执行下面的函数。
      clearTimeout(timeId);
      timeId = setTimeout(() => {
        this.scrollToTop();
        // console.log('执行完了哦');
      }, 1);
    } , true);
    window.addEventListener('scroll', this.handleScroll)
    this.getRecList()
    this.getTopInfo()

  },
  methods: {
    // 点击导航菜单，页面滚动到指定位置
    handleLeft(index) {
      this.currentIndex = index;

      this.$el.querySelector(`#id${index}`).scrollIntoView({
        behavior: "smooth",  // 平滑过渡
        block:    "start"  // 上边框与视窗顶部平齐。默认值
      });
      this.listBoxState=false;

      let timeId;
      clearTimeout(timeId);
      timeId = setTimeout(() => {
        this.listBoxState=true;
      },1);
    },
    // 监听页面元素滚动，改变导航栏选中
    scrollToTop() {
      // 获取视窗高度
      // var domHight = document.body.offsetHeight;
      // dom滚动位置
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

      if (this.listBoxState) {//作用是点击导航栏时，延迟这里执行。
        this.recList.map((v,i) => {
          // 获取监听元素距离视窗顶部距离
          if(document.getElementById(`id${i}`)){
            let offsetTop = document.getElementById(`id${i}`).offsetTop;
            // 获取监听元素本身高度
            let scrollHeight = document.getElementById(`id${i}`).scrollHeight;

            // 如果 dom滚动位置 >= 元素距离视窗距离 && dom滚动位置 <= 元素距离视窗距离+元素本身高度
            // 则表示页面已经滚动到可视区了。
            if (scrollTop >= offsetTop && scrollTop<=(offsetTop+scrollHeight)) {
              // 导航栏背景色选中
              this.currentIndex = i;
            }
          }
        })
      }
    },
    handleScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      // if(document.querySelector(".leftUl")){
      //   var offsetTop = document.querySelector(".leftUl").offsetTop
      // }
      // console.log(scrollTop)
      // console.log(offsetTop)
      if (scrollTop > 500) {
        this.leftFixed = true
        // console.log("----------",document.body.clientHeight-1200)
        // console.log("==============",scrollTop)
      }else{
        this.leftFixed = false
        // console.log("hahaha")
      }

      if(this.currentIndex == this.recList.length-1){
        this.isBtm = true
        this.leftFixed = false
      }else if(scrollTop <= 500){
        this.leftFixed= false
        this.isBtm = false
      }else{
        this.isBtm = false
        this.leftFixed = true
      }

      // if((document.body.clientHeight -1200) < scrollTop){
      //   // document.getElementById("leftUlBox").style.position = "absolute"
      //   // document.getElementById("leftUlBox").style.bottom = "0"
      // }else{
      //   // document.getElementById("leftUlBox").remove.position
      //   // document.getElementById("leftUlBox").remove.bottom
      // }

    },
    getRecList(){
      axios.get('/recruit/list').then(
          response => {
            console.log(response.data)
            this.recList = response.data
            // this.newsList = this.newsListBig.slice(0,5)
          },
          error => {
            console.log(error.message)
          }
      )
    },
    getTopInfo() {
      axios.get('/companyInfo/list').then(
          response => {
            // console.log("asdasdsadasdas",response.data[0])
            this.topInfo = response.data[0]
            this.emailAdd = this.topInfo.email
          },
          error => {
            console.log(error.message)
          }
      )
    },
    toMail () {
      let clipboard = new ClipboardJS('.offerBtn')
      clipboard.on('success', () => {
        alert('已将邮箱地址复制到剪贴板。')
        //  释放内存
        clipboard.destroy()
      })
      clipboard.on('error', () =>{
        // 不支持复制
        alert('该浏览器不支持复制')
        // 释放内存
        clipboard.destroy()
      })
    }

  },
  beforeDestroy() {
    // window.removeEventListener('scroll', this.handleScroll2)
  },
}
</script>

<style scoped>
.bigBox{
  position: relative;
}
.banner {
  width: 100%;
  height: 560px;
  background: url("image/recruitBg.jpg") center center no-repeat;
  /*background-color: #333;*/
  background-size: cover;
}

.bannerMain {
  width: 1200px;
  position: relative;
  margin: 0 auto;
}

.bannerMain > p {
  font-size: 52px;
  font-style: italic;
  display: inline-block;
  width: 1200px;
  position: absolute;
  top: 240px;
  color: #ffffff;
  font-weight: bold;
}
.mainBox{
  width: 100%;
  background-color: #f2f2f2;
  padding-top: 24px;
}
.mainContainer{
  width: 1200px;
  margin: 0 auto;
  position: relative;
}
.leftUlBg{
  width: 288px;
  margin-right: 24px;
  /*height: 100%;*/
}
.leftUl{
  width: 288px;
  max-height: 700px;
  overflow-y: auto;
  overflow-x: hidden;
}
.leftUl li{
  width: 288px;
}
.leftUlBox{
  width: 100%;
  z-index: 9;
  position: absolute;
  padding-top: 24px;
}


.leftFixed{
  position: fixed;
  left: 0;
  top: 70px;
}
.isFixBtm{
  position: absolute;
  bottom: 84px;
}
.leftUl{
  /*box-shadow: 0 0 5px 3px rgba(0,0,0,0.05);*/
}
.leftLi{
  padding: 16px 16px;
  color: #333;
  font-weight: normal;
  background-color: #fff;
  border-bottom: 1px solid #f1f1f1;
}

.leftUl .recTitle{
  font-size: 14px;
  font-weight: bold;
  line-height: 240%;
  /*color: #333;*/
}
.recSmallTitle{
  font-size: 12px;
}
.leftUl .isActive{
  background-color: #ff7d22;
  color: #fff;
}
.rightList{
  width: calc(100% - 312px);
  padding-top: 86px;
  z-index: 10;
}
.rightList li{
  margin-bottom: 24px;
  /*box-shadow: 0 0 5px 3px rgba(0,0,0,0.05);*/
  background-color: #fff;
  padding: 24px;

}
.rightP1,.rightP2{
  width: 50%;
  padding: 0 12px;
  line-height: 150%;
}
.rightTitle{
  font-size: 18px;
  color: #333;
  margin-bottom: 12px;
  font-weight: bold;
}
.rightBigTitle{
  font-size: 24px;
  padding-left: 12px;
  margin-bottom: 6px;
  color: #333;
  font-weight: bold;
}
.rightSmallTitle{
  font-size: 16px;
  padding-left: 12px;
  margin-bottom: 24px;
}
.offerBtn{
  width: calc(100% - 312px);
  border: 0 none;
  background-color: #ff7d22;
  line-height: 240%;
  padding: 16px 0;
  border-radius: 2px;
  color: #fff;
  position: absolute;
  z-index: 99;
  top: 0;
  left: 312px;
  transition: 0.5s;
  font-size: 14px;
}
.offerBtn:hover{
  background-color: #DF6917;
  cursor: pointer;
}
</style>