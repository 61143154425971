<template>
  <div class="aboutBox">
    <div class="banner" title="合友科技">
      <div class="bannerMain">
        <p>天作之合，友如星月</p>
      </div>
    </div>
    <div class="underBanner" :class="navFixed2 == true ? 'isFixed2' : '' " title="合友科技">
      <ul class="udBannerList clear" id="aboutNav">
        <li @click="toCompany">公司简介</li>
        <li @click="toThings">公司事记</li>
        <li @click="toProduct">主营产品</li>
        <li @click="toCulture">何谓合友</li>
        <li @click="toAddress">公司地点</li>
      </ul>
    </div>
    <div id="pro1" class="companyInt">
      <div class="companyContainer">
        <p class="title">关于合友</p>
        <p class="text boxSizing">
          <span class="textBlack">{{ topInfo.strStrong }}</span><br/>
          {{ topInfo.str }}
        </p>
        <ul class="udText clear">
          <li class="fl boxSizing" v-for="item in aboutUsMiddle" :key="item.id">
            <p class="udTextTitleCh">{{ item.small_title }}</p>
            <p class="udTextTitleEn">{{ item.english_title }}</p>
            <p class="udTextText" v-html="item.content"></p>
          </li>
          <!--          <div class="line fl"></div>-->
        </ul>
      </div>
    </div>
    <div id="pro2" class="history">
      <div class="historyBg boxSizing">
        <div class="historyBtn">
          <ul id="historyBtnBox">
            <li v-for="(item,index) in historyList" :key="item.id" @click="getIndex(index)" :class="index == current?'activeHistory':''">
              <p>{{ item.year }}</p>
              <div class="historyBtnDetail"></div>
            </li>
          </ul>
        </div>
      </div>
      <div class="historyText">
        <ul class="clear" id="historyBox" :style="marginLeft">
          <li v-for="item in historyList" :key="item.id" class="fl">
            <p class="yearText">{{ item.year }}</p>
            <ul>
              <li v-for="item in item.things" :key="item.id" class="monthHistory">
                {{ item.month }}月-{{ item.title }}
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div id="pro3" class="mainProductBox">
      <div class="mainProductContainer">
        <p class="mainProductTitle title gray">主要产品</p>
        <p class="mainProductText">合友公司旨在为客户提供优质服务，为客户赋能，提高工作效率，桥接各部门，让工作更有序，更高效，更便捷。</p>
        <ul class="mainProduct clear">
          <li v-for="item in product" :key="item.id" class="fl" @click="showDialog(item)">
            <div class="productIcon">
              <img :src="baseUrl+item.icon" alt="111" />
            </div>
            <p class="productTitle textOver1">{{ item.name }}</p>
            <p class="productText textOver1">{{ item.subTitle }}</p>
          </li>
        </ul>

      </div>
    </div>
    <div id="pro4" class="cultureBox">
      <div class="culture clear" v-if="aboutUsInfo">
        <div class="cultureWords fl">
          <p class="cultureTitle"><span class="cultureTitleBig">{{ lastAbout.title.slice(0,1) }}</span>{{ lastAbout.title.slice(1) }}<span
              class="cultureTitleEn">{{ lastAbout.english_title }}</span></p>
          <p class="udCultureTitle orange">内涵有三</p>
          <p v-html="lastAbout.content" class="cultureTextP"></p>
          <p class="udCultureText orange">天作之<span class="udCultureTextBig">合</span>,<span
              class="udCultureTextBig">友</span>如星月</p>
        </div>
        <div class="culturePic fr"></div>
      </div>
    </div>
    <div id="pro5" class="addressBox">
      <div class="address">
      </div>
      <MyMap
          :address="topInfo.address"
          :email="topInfo.email"
          :latY="Number(topInfo.latY)"
          :lngX="Number(topInfo.lngX)"
          :tel="topInfo.tel"
          v-if="Number(topInfo.latY)&&Number(topInfo.lngX) != ''">
      </MyMap>
    </div>
    <div class="maskBg" v-show="showDialog11">
      <DiaLog :showOrNot="showDialog11"
              :title="title"
              :text="text"
              :content="content"
              :url="url"
              :picture="picture"
              :code="code"
              @handleChange="changeShowOrNot"
              v-show="showDialog11">
      </DiaLog>
    </div>
  </div>
</template>

<script>
import DiaLog from "@/components/PC/Dialog/Dialog";
import MyMap from "@/components/PC/Map/MyMap";
import axios from "axios";
import global from "@/config/global";

export default {
  name: "AboutUs",
  components: {
    MyMap,
    DiaLog
  },
  data() {
    return {
      product:'',
      showDialog11: false,
      title: '',
      content: '',
      text: '',
      url: '',
      navFixed2: false,
      marginLeft: '',
      current:0,
      oldHistoryList:'',
      historyList:'',
      aboutUsInfo:'',
      aboutUsMiddle:'',
      topInfo:'',
      baseUrl:'',
      picture:'',
      code:'',
      historyBottomList:''
    }
  },
  mounted() {
    this.baseUrl = global.BASE_URL
    // console.log(this.baseUrl)
    window.addEventListener('scroll', this.handleScroll2)
    this.getHistoryList()
    this.getAboutInfo()
    this.getTopInfo()
    // this.turnHistory()
    this.getProList()
  },
  methods: {
    showDialog(value) {
      this.showDialog11 = true
      // console.log(value)
      this.title = value.name
      this.text = value.subTitle
      this.content = value.description
      this.picture = this.baseUrl+value.icon
      this.code = value.code
      this.url = value.url
    },
    changeShowOrNot(tof) {
      this.showDialog11 = tof
      // console.log(this.showDialog11)
    },
    toCompany() {
      document.getElementById("pro1").scrollIntoView({behavior: "smooth"})
    },
    toThings() {
      document.getElementById("pro2").scrollIntoView({behavior: "smooth"})
    },
    toProduct() {
      document.getElementById("pro3").scrollIntoView({behavior: "smooth"})
    },
    toCulture() {
      document.getElementById("pro4").scrollIntoView({behavior: "smooth"})
    },
    toAddress() {
      document.getElementById("pro5").scrollIntoView({behavior: "smooth"})
    },

    handleScroll2() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      let offsetTop = document.querySelector(".udBannerList").clientHeight
      // console.log('scrollTop',scrollTop)
      // console.log('offsetTop',offsetTop)
      if (scrollTop > (offsetTop + 100)) {
        // console.log('true')
        this.navFixed2 = true
      } else {
        this.navFixed2 = false
      }
    },
    getIndex(index) {
      this.current = index
      // console.log(this.current)
      this.marginLeft = 'margin-left:'+ -this.current * 403 + 'px'
      // console.log(this.marginLeft)
    },

    getAboutInfo() {
      axios.get('/points_img/queryPointsList').then(
          response => {
            // console.log(response.data)
            this.aboutUsInfo = response.data
            this.lastAbout = this.aboutUsInfo[this.aboutUsInfo.length-1]
            let oldArr = response.data
            let newArr = []
            for(let i = 0;i < oldArr.length;i++){
              if(oldArr[i].sort == '2'){
                newArr.push(oldArr[i])
              }
            }
            this.aboutUsMiddle = newArr
            this.aboutUsSmall = newArr.slice(0,2)
          },
          error => {
            console.log(error.message)
          }
      )
    },
    getTopInfo() {
      axios.get('/companyInfo/list').then(
          response => {
            // console.log("asdasdsadasdas",response.data[0])
            this.topInfo = response.data[0]
          },
          error => {
            console.log(error.message)
          }
      )
    },
    getHistoryList() {
      axios.get('/photo/queryPhotoList').then(
          response => {
            // console.log(response.data)
            this.oldHistoryList = response.data
            function compare(property){
              return function (a,b){
                let value1 = a[property]
                let value2 = b[property]
                return value2-value1
              }
            }
            this.historyList = this.oldHistoryList.sort(compare('year'))
          },
          error => {
            console.log(error.message)
          }
      )
    },
    getProList() {
      axios.get('/product/list').then(
          response => {
            this.oldProList = response.data
            function compare(property){
              return function (a,b){
                let value1 = a[property]
                let value2 = b[property]
                return value1-value2
              }
            }
            this.product = this.oldProList.sort(compare('sort'))
          },
          error => {
            console.log(error.message)
          }
      )
    },


  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll2)
  },
}
</script>

<style scoped>
.banner {
  width: 100%;
  height: 560px;
  background: url("image/aboutUsBanner.jpg") center center no-repeat;
  background-size: cover;
}

.bannerMain {
  width: 1200px;
  position: relative;
  margin: 0 auto;
}

.bannerMain > p {
  font-size: 52px;
  font-style: italic;
  display: inline-block;
  width: 1200px;
  position: absolute;
  top: 240px;
  color: #ffffff;
  font-weight: bold;
}

/*banner之下*/
.underBanner {
  width: 100%;
  background-color: #fbfbfb;
  border-bottom: 1px solid #ededed;
  transition: 0.5s;
}

.udBannerList {
  width: 1200px;
  margin: 0 auto;
}

.udBannerList li {
  font-size: 16px;
  line-height: 52px;
  margin: 0 32px;
  float: left;
}

.isFixed2 {
  position: fixed;
  z-index: 1000;
  background-color: #fff;
  width: 100%;
  border-bottom: 1px solid #ededed;
  top: 0;
  left: 0;
}

.isFixed2 li {
  height: 80px;
  line-height: 80px;
}

.companyInt {
  background-color: #fff;
  width: 100%;
  padding: 96px 0;
}

.companyContainer {
  width: 1200px;
  margin: 0 auto;
}

.title {
  font-style: italic;
  font-weight: bold;
  font-size: 32px;
  color: #333;
  text-align: center;
}

.text {
  font-size: 16px;
  color: #333333;
  width: 100%;
  padding-left: 32px;
}

.textBlack {
  font-weight: bold;
  display: block;
  margin: 48px 0;
}

.udText {
  width: 1136px;
  background-color: #fafafa;
  padding: 44px 0;
  margin: 72px auto 0 auto;
}

.udText li {
  width: 378px;
  padding: 0 82px;
  position: relative;
}

.udText li:last-child {
  border: 0 none;
}

.udTextTitleCh {
  font-size: 30px;
  font-style: italic;
  color: #333;
}

.udTextTitleEn {
  font-size: 14px;
  line-height: 52px;
  color: #dbdbdb;
}

.udTextText {
  font-size: 14px;
  line-height: 28px;
}

.line ,.udText li:after{
  display: block;
  width: 1px;
  height: 120px;
  background-color: #eeeeee;
  margin-top: 30px;
}
.udText li:after{
  content: '';
  position: absolute;
  right: 0;
  top:10px;
}
.udText li:last-child:after{
  display: none;
}
#aboutNav li{
  cursor: pointer;
}
.history {
  padding-bottom: 48px;
}

#historyBox {
  transition: 0.5s;
}

.historyBg {
  width: 100%;
  background: url("image/history.jpg") center center no-repeat;
  height: 292px;
  padding-top: 20px;
}

.historyBtn {
  width: 973px;
  height: 260px;
  background: url("image/historyBtn2.svg") center center no-repeat;
  /*background-size: cover;*/
  margin: 0 auto;
}

.historyBtn {
  position: relative;
}

.historyBtn > ul > li {
  position: absolute;
  font-size: 20px;
  line-height: 100%;
  color: #ffffff;
}

.historyBtn > ul > li:hover{
  cursor: pointer;
}

.historyBtnDetail {
  width: 12px;
  height: 12px;
  background: url("image/historyNormal.svg") center center no-repeat;
  margin: 15px auto;
}
.activeHistory .historyBtnDetail{
  background: url("image/historyActive.svg") center center no-repeat;
}

.historyBtn > ul > li{
  top: 89px;
}
.historyBtn > ul > li:first-child {
  left: 0;
}

.historyBtn > ul > li:nth-child(2) {
  left: 178px;
}

.historyBtn > ul > li:nth-child(3) {
  left: 369px;
}

.historyBtn > ul > li:nth-child(4) {
  left: 560px;
}

.historyBtn > ul > li:nth-child(5) {
  right: 177px;
}

.historyBtn > ul > li:nth-child(6) {
  right: 0;
}

.historyText {
  width: 1200px;
  margin: 40px auto 0 auto;
  overflow: auto;
}

.historyText > ul {
  width: 3200px;
}

.historyText > ul > li {
  width: 383px;
  margin-right: 24px;
  padding-bottom: 24px;
}

.yearText {
  font-size: 22px;
  font-weight: bold;
  font-style: italic;
  line-height: 44px;
  color: #333;
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: 24px;
}

.monthHistory {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 16px;
  color: #64615F;
}

/*主要产品*/
.mainProductBox {
  padding-top: 48px;
  background-color: #fff;
  padding-bottom: 96px;
}

.mainProductText {
  font-size: 16px;
  line-height: 100%;
  margin: 48px 0;
  text-align: center;
}

.mainProductContainer {
  width: 1200px;
  margin: 0 auto;
}

.mainProduct li {
  width: 220px;
  height: 220px;
  padding-top: 40px;
  box-sizing: border-box;
  margin-right: 25px;
  transition: 0.5s;
}

.mainProduct li:nth-child(5n) {
  margin-right: 0;
}

.mainProduct li:hover {
  background-color: #fbfcfa;
  cursor: pointer;
}

.mainProduct li div img{
  display: block;
  margin: 0 auto;
  height: 75px;
  width: auto;
}

/*.mainProduct li:first-child div {*/
/*  background: url("image/1.svg") center center no-repeat;*/
/*}*/

/*.mainProduct li:nth-child(2) div {*/
/*  background: url("image/2.svg") center center no-repeat;*/
/*}*/

/*.mainProduct li:nth-child(3) div {*/
/*  background: url("image/3.svg") center center no-repeat;*/
/*}*/

/*.mainProduct li:nth-child(4) div {*/
/*  background: url("image/4.svg") center center no-repeat;*/
/*}*/

/*.mainProduct li:nth-child(5) div {*/
/*  background: url("image/5.svg") center center no-repeat;*/
/*}*/

.productTitle {
  font-size: 20px;
  line-height: 30px;
  margin-top: 15px;
  text-align: center;
  font-weight: bold;
  color: #333;
}

.productText {
  text-align: center;
  font-size: 14px;
}

/*企业文化*/
.culture {
  width: 1200px;
  margin: 0 auto;
}

.cultureTitle {
  font-size: 24px;
  font-weight: normal;
  text-align: left;
  font-style: italic;
}

.cultureWords {
  padding-top: 72px;
}

.cultureTitleBig {
  font-size: 38px;
  color: #ff7d22;
}

.cultureTitleEn {
  font-family: "Gill Sans";
  font-size: 16px;
  color: #bdbdbd;
  margin-left: 12px;
}

.udCultureTitle {
  margin: 40px 0 24px 0;
  font-size: 16px;
}

.cultureText li {
  font-size: 16px;
  margin-bottom: 54px;
}
.cultureTextP{
  font-size: 16px;
  line-height: 72px;
  margin-bottom: 24px;
}

.udCultureText {
  font-size: 16px;
  font-weight: bold;
}

.udCultureText span {
  font-size: 24px;
}

.culturePic {
  width: 600px;
  height: 532px;
  background: url("image/culture.jpg") center center no-repeat;
  background-size: cover;
}

.maskBg {
  width: 100%;
  height: 100%;
  /*background-color: rgba(0,0,0,0.6);*/
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 999999;
  left: 0;
  top: 0;
}

/*公司地点*/
</style>