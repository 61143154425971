import { render, staticRenderFns } from "./MoNewsInner.vue?vue&type=template&id=5550e879&scoped=true&"
import script from "./MoNewsInner.vue?vue&type=script&lang=js&"
export * from "./MoNewsInner.vue?vue&type=script&lang=js&"
import style0 from "./MoNewsInner.vue?vue&type=style&index=0&id=5550e879&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5550e879",
  null
  
)

export default component.exports