<template>
  <div class="boxSizing mainBox">
    <div class="addMsg boxSizing clear">
      <p class="moTitle leftTitle gray">办公地点</p>
      <div class="addTextBox fl boxSizing">
        <p class="addTitle">办公地点</p>
        <p class="addText">{{ address }}</p>
        <p class="addTitle">联系电话</p>
        <p class="addText">{{ tel }}</p>
        <p class="addTitle">联系邮箱</p>
        <p class="addText">{{ email }}</p>
      </div>
      <div class="addMap fl">
        <div class="addMapTop"></div>
        <div class="addMapBottom"></div>
        <!--        <el-amap vid="MyMap"></el-amap>-->
        <el-amap
            ref="map"
            :vid="'amapDemo'"
            :center="center"
            :zoom="zoom"
            :plugin="plugin"
            :events="events"
            class="amap-demo"
        >
          <!--          <el-amap-marker v-for="u in markers" :position="u.position"></el-amap-marker>-->
          <el-amap-marker :position="[this.lngX,this.latY]" ></el-amap-marker>
        </el-amap>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    address: {
      type: String,
      default: ''
    },
    email: {
      type: String,
      default: ''
    },
    latY: {
      type: Number
    },
    lngX: {
      type: Number
    },
    tel: {
      type: String,
      default: ''
    },
  },
  // mounted() {
  //   this.findType()
  // },
  data() {
    return {
      // center: [121.5273285, 31.21515044],
      lngX_0:'',
      latY_0:'',
      center: [this.lngX,this.latY],
      zoom: 15,
      position: [this.lngX,this.latY],
      icon: '/huoche.png',
      events: {
        // init(o){
        //   console.log(o.getCenter());
        // },
        // zoomchange: (e) => {
        //   console.log(e);
        // },
        // zoomend: (e) => {
        //   //获取当前缩放zoom值
        //   console.log(this.$refs.map.$$getInstance().getZoom());
        //   console.log(e);
        // },
      },
      markers: [
        {
          position: [this.lngX,this.latY]
        }
      ],
//使用其他组件
      plugin: [
        {
          pName: 'Scale',
          events: {
            init(instance) {
              console.log(instance)
            }
          }
        },
        {
          pName: 'ToolBar',
          events: {
            init(instance) {
              console.log(instance)
            }
          }
        }
      ]
    }
  },
  methods:{
    // findType(){
    //   console.log(typeof (this.latY))
    //   console.log(this.latY)
    // }
  },
  // beforeDestroy() {
  //   this.findType()
  // }
}
</script>

<style scoped>
.mainBox{
  width: 100%;
  background-color: #F6F6F6;
  padding: 1rem 0 4rem 0;
}
.addMsg{
  width: 100%;
  margin: 0 auto;
}
.addTextBox{
  width: 37.5rem;
  padding: 4.8rem 2.4rem 0 2.4rem;
}
.addMap{
  width: 100%;
  height: 24.7rem;
  position: relative;
}

.addMapTop,.addMapBottom{
  width: 9.5rem;
  height: 9.1rem;
  background: url("./image/mapBg1.svg") center center no-repeat;
}
.addMapTop{
  top: 0;
  right: 0;
}
.addMapBottom{
  bottom: 0;
  left: 0;
}
.addMapTop,.addMapBottom{
  position: absolute;
  background-size: cover;
}
.addTitle{
  font-size: 1.8rem;
  line-height: 1.8rem;
  color: #333;
  margin-bottom: 1.6rem;
}
.addText{
  font-size: 1.4rem;
  line-height: 2.8rem;
  color: #333;
  margin-bottom: 3.2rem;
}
.amap-demo{
  width: 32.7rem;
  height: 20.5rem;
  margin: 2rem auto 0 auto;
}
</style>