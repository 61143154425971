<template>
  <div class="boxSizing">
    <div class="banner">
      <div class="bannerMain">
        <p>合友动态</p>
      </div>
    </div>
    <SmallNews></SmallNews>
  </div>
</template>

<script>
// import HeadEr from "@/components/Header/Head";
import SmallNews from "@/components/PC/SmallNews/SmallNews";
export default {
  name: "NewsList",
  components: {SmallNews
    // , HeadEr
  }
}
</script>

<style scoped>
.banner{
  width: 100%;
  height: 560px;
  background: url("image/banner.jpg") center center no-repeat;
  background-size: cover;
}
.bannerMain{
  width: 1200px;
  position: relative;
  margin: 0 auto;
}
.bannerMain>p{
  font-size: 52px;
  font-style: italic;
  display: inline-block;
  width: 1200px;
  position: absolute;
  top: 240px;
  font-weight: bold;
  color: #ffffff;
}
</style>