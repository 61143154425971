<template>
  <div class="footBox boxSizing">
    <div class="titleTop clear">
      <p class="titleBig fl">联系我们</p>
      <ul class="fl clear footIconBox">
        <li v-for="(item,index) in aboutList" :key="item.id" class="fl footIcon">
          <a href="javascript:void(0)" @click="showInfo(index)">
            <img :src="baseUrl+item.icon"/>
          </a>
          <div class="infoBox" v-if="item.code !== ''" :class="currentIndex === index ? 'showThePic' : ''" @click="showInfo"><img :src="baseUrl+item.code"/></div>
        </li>
      </ul>
    </div>
    <ul class="footInner">
<!--      <li class="btmNav" v-for="(item,index) in btmList" :key="item.id" ref="btmLi"  @click="showList(index)">-->
      <li class="btmNav" v-for="(item,index) in btmList" :key="item.id" ref="btmLi"  @click="showList(index)" :class="flag == true?(index == current?'highOne':''):''">
        <div class="clear titleBox">
          <a href="javascript:void(0)" class="titleBig fl titleBig2">{{ item.name }}</a>
          <span class="arr fr" :class="flag == true?(index == current?'arrDown':''):''" id="arr"></span>
        </div>
        <router-link class="udBtmNav" v-for="item2 in item.smallList" :key="item2.id" :to="item2.path">{{ item2.name }}</router-link>
      </li>
<!--      <li class="btmNav" id="btmNav2" @click="showList()">-->
<!--        <div class="clear titleBox">-->
<!--          <a href="javascript:void(0)" class="titleBig fl titleBig2">加入我们</a>-->
<!--          <span class="arr fr"></span>-->
<!--        </div>-->
<!--        <a class="udBtmNav">关于我们</a>-->
<!--      </li>-->
    </ul>
    <a href="https://beian.miit.gov.cn/#/Integrated/index" class="bottomInfo">法律声明   陕ICP备19022882号-5</a>
    <p class="bottomText" v-html="topInfo.content"></p>
  </div>
</template>

<script>
import axios from "axios";
import global from "@/config/global";

export default {
  name: "MobileFoot",
  data() {
    return {
      aboutList: '',
      topInfo: '',
      baseUrl: '',
      newFoot: false,
      btmList:[
        {id:'01',name:'关于我们',smallList:[{id:'01',name:'关于我们',path:'/MobileAbout'}]},
        {id:'02',name:'加入我们',smallList:[{id:'01',name:'社会招聘',path:'/MoRecruit'}]}
      ],
      flag:false,
      current:'-1',
      showInOrNot:false,
      currentIndex:''
    }
  },
  created() {
    this.baseUrl = global.BASE_URL
  },
  mounted() {
    this.getContactList()
    this.getTopInfo()
  },
  methods: {
    getContactList() {
      axios.get('/about/list').then(
          response => {
            this.aboutList = response.data
            console.log("嘿嘿嘿",this.aboutList)
          },
          error => {
            console.log(error.message)
          }
      )
    },
    getTopInfo() {
      axios.get('/companyInfo/list').then(
          response => {
            // console.log("asdasdsadasdas",response.data[0])
            this.topInfo = response.data[0]
          },
          error => {
            console.log(error.message)
          }
      )
    },
    // showList(index) {
    //   // console.log(this.$refs.btmLi[index])
    //   let navHeight = this.$refs.btmLi[index]
    //
    // }
    showList(index){
      this.current = index
      this.flag = !this.flag
    },
    showInfo(index){
      this.currentIndex = index,
      this.showInOrNot = !this.showInOrNot
    }


  },
  // watch:{
  //   'current':function(newValue,oldValue){
  //     console.log(newValue,oldValue)
  //     if(newValue === oldValue){
  //       console.log("2222")
  //       // this.current = '-1'
  //     }
  //   }
  // },
}
</script>

<style scoped>
.footBox {
  padding: 0 2.4rem 2.4rem 2.4rem;
}

.titleTop {
  padding: 2rem 0 1.4rem 0;
}

.titleBig {
  display: block;
  font-size: 1.6rem;
  line-height: 150%;
  color: #333;
}

.footIconBox {
  margin-left: 2.8rem;
}

.footIcon{
  position: relative;
}

.footIcon>a>img {
  display: block;
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 1.2rem;
}

.arr {
  display: block;
  width: 1.2rem;
  height: 2.2rem;
  background: url("./image/arr.svg") center center no-repeat;
}

.footInner{
  margin-bottom: 2.5rem;
}

.footInner li.btmNav {
  /*height: 2.4rem;*/
  overflow: hidden;
  transition: 0.3s;
  height: 5.4rem;

}

.titleBox{
  height: 5.4rem;
}

.udBtmNav {
  line-height: 5.4rem;
  color: #999;
  font-size: 1.4rem;
  display: block;
}

.titleBig2{
  line-height: 5.4rem;
}
.arr{
  margin-top: 1.5rem;
}
.footInner li.highOne{
  height: 10.8rem;
}

#arr{
  transition: 0.3s;
}
.arrDown{
  transform: rotate(90deg);
}

.bottomText{
  font-size: 1.2rem;
  color: #999;
  line-height: 2.4rem;
}
.bottomInfo{
  font-size: 1.2rem;
  color: #999;
}

.infoBox{
  display: none;
  position: absolute;
  top: -100%;
  left: 0;
  z-index: 5;
}

.showThePic{
  display: block;
}
</style>