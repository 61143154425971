<template>
  <div class="footBox" :class="newFoot == true?'darkFoot':''">
    <div class="footMid boxSizing">
      <div class="footMidTop clear" :class="newFoot == true ? 'darkFootMidTop':''">
        <p class="footMidTopWord fl">联系我们</p>
        <ul class="clear fl footIconBox">
          <li class="fl footMidTopIcon" v-for="item in aboutList" :key="item.id">
            <a href="javascript:void(0)">
              <img :src="baseUrl+item.icon"/>
<!--              <img :src="baseUrl+item.icon"/>-->
            </a>
            <div class="imgBox" :class="item.code == ''?'disNone':'' "><img :src="baseUrl+item.code"/></div>
          </li>
        </ul>
      </div>
      <div class="footMidMid clear">
        <ul class="clear fl">
          <li class="fl">
            <p>关于我们</p>
            <ul>
              <li>
                <router-link to="/about">公司简介</router-link>
                <!--                <a>校园招聘</a>-->
              </li>
            </ul>
          </li>
          <li class="fl">
            <p>加入我们</p>
            <ul>
              <li>
                <router-link to="/recruit">社会招聘</router-link>
                <!--                <a>校园招聘</a>-->
              </li>
            </ul>
          </li>
          <!--          <li class="fl">-->
          <!--            <p>法律信息</p>-->
          <!--            <ul>-->
          <!--              <li>-->
          <!--                <a>服务协议</a>-->
          <!--                <a>隐私政策</a>-->
          <!--                <a>知识产权</a>-->
          <!--              </li>-->
          <!--            </ul>-->
          <!--          </li>-->
        </ul>
        <img src="image/logoOrange.svg" class="footLogo"/>
      </div>
      <div class="footBottom clear">
        <a class="fl" href="https://beian.miit.gov.cn/#/Integrated/index">法律声明   陕ICP备19022882号-5</a>
        <p class="fr" v-html="topInfo.content"></p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import global from "@/config/global";
export default {
  name: "FootEr",
  data(){
    return{
      aboutList:'',
      topInfo:'',
      baseUrl:'',
      newFoot:false
    }
  },
  created() {
    this.baseUrl = global.BASE_URL
  },
  mounted() {
    this.getContactList()
    this.getTopInfo()
  },
  methods:{
    getContactList() {
      axios.get('/about/list').then(
          response => {
            this.aboutList = response.data
            // console.log("嘿嘿嘿",this.aboutList)
          },
          error => {
            console.log(error.message)
          }
      )
    },
    getTopInfo() {
      axios.get('/companyInfo/list').then(
          response => {
            // console.log("asdasdsadasdas",response.data[0])
            this.topInfo = response.data[0]
          },
          error => {
            console.log(error.message)
          }
      )
    },

  },
  watch:{
    '$route.path':function(newValue,oldValue){
      // console.log(newValue,oldValue)
      if(newValue === '/recruit'){
        this.newFoot = true
      }else{
        this.newFoot = false
        console.log(oldValue)
      }
    }
  },
}
</script>

<style scoped lang="less">
.footBox{
  background-color: #fbfbfb;
  width: 100%;
}
.footMid{
  width: 1200px;
  margin: 0 auto;
  padding: 0 0 60px 24px;
}
.footMidTop{
  border-bottom: 1px solid #f2f3f5;
}
.darkFootMidTop{
  border-bottom: 1px solid #ccc;
}
.footMidTopWord{
  font-size: 24px;
  line-height: 120px;
  margin-right: 48px;
  color: #64615F;
}
.footIconBox li{
  margin-right: 32px;
  margin-top: 44px;
}
.footIconBox li:first-child{
  /*background:url(./image/weChat.svg) center center no-repeat*/
}
.footIconBox li:nth-child(2){
  /*background:url(./image/weiBo.svg) center center no-repeat*/
}
.footIconBox li:nth-child(3){
  /*background:url(./image/tikTok.svg) center center no-repeat*/
}
.footMidTopIcon{
  display: block;
  width: 32px;
  height: 32px;
}
.footMidMid{
  position: relative;
}
.footMidMid p{
  font-size: 20px;
  line-height: 100%;
  margin: 48px 0 32px 0;
  color: #64615F;
}
.footMidMid a{
  font-size: 16px;
  line-height: 100%;
  color: #64615F;
  display: block;
  margin-bottom: 24px;
  position: relative;
}
.footMidMid>ul>li{
  margin-right: 184px;
}
.footMidMid>ul>li:last-child{
  margin-right: 0;
}
.footLogo{
  display: block;
  width: 255px;
  height: 85px;
  position: absolute;
  right: 45px;
  top: 80px;
}
.footBottom{
  margin-top: 160px;
}
.footBottom>p,.footBottom>a{
  font-size: 14px;
  color: #64615F;
}
.imgBox{
  width: 200px;
  height: 200px;
  display: none;
  //background-color: red;
  position: absolute;
  top: -200px;
  left:0
}
.imgBox img{
  display: block;
  width: 200px;
  height: 200px;
}
.footIconBox li:hover div{
  z-index:999;
  display: block;
}
.disNone{
  display: none!important;
}
.darkFoot{
  background-color:#e9e9e9;
}
</style>