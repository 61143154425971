<template>
  <div class="dialog boxSizing clear" @keyup.esc="closeMask">
    <div class="close fr" @click="closeMask"></div>
    <div class="icon fl"><img :src="picture"/></div>
    <div class="iconRight fl">
      <div class="iconRightContainer">
        <div class="titleBig clear">
          <div class="titleBigText fl">{{ title }}</div>
        </div>
        <div class="titleSmall clear">
          <div class="textBig fl">{{ text }}</div>
          <div>
            <a @click="tanShow" class="link fr" v-if="url != ''&&code==''">前往官网</a>
            <a class="link fr" v-if="url == ''" @click="clickToSoN">
              前往打开
            </a>
            <a class="link fr" v-if="url != ''&&code!=''" @click="tanShow">
<!--              <span v-show="showEwmOrNot === false">下载{{ title }}</span><span-->
<!--                v-show="showEwmOrNot === true">前往{{ title }}官网</span>-->
              前往官网
            </a>
          </div>
        </div>

      </div>
      <p class="dialogText" v-html="content.replace(/<[^>]+>/g,'')"></p>
      <div v-if="tan" class="dialogLeaveOrNotBox boxSizing" @click="dontLeave">
        <div class="dialogLeaveOrNot boxSizing">
          <div class="clear diaTitleBox">
            <p class="fl dialogInnerTitle">确认</p>
            <div class="fr close" style="margin-right: 2rem;" @click="dontLeave"></div>
          </div>
          <p class="alertTitle">即将离开，是否前往新页面？</p>
          <div class="alertBtnBox clear">
            <div class="clear fr">
              <div class="alertCancel fl" @click="dontLeaveAndOpen">下载app</div>
              <div class="alertCancel fl" @click="dontLeave">取消</div>
              <div class="alertSure fl" @click="leave">确认</div>
            </div>
          </div>
        </div>
      </div>
      <img class="ewm" v-if="showEwmOrNot" :src="this.baseUrl+code"/>
      <div class="dialogMask" v-show="showEwmOrNot" @click="closeDialogMask"></div>
    </div>
  </div>
</template>

<script>
import global from "@/config/global";

export default {
  name: "MoDiaLog",
  data() {
    return {
      showEwmOrNot: false,
      baseUrl: global.BASE_URL,
      sureToLeave: '',
      tan: false
    }
  },
  props: {
    showOrNot: {
      type: Boolean,
      default: false
    },
    content: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: ''
    },
    picture: {
      type: String,
      default: ''
    },
    code: {
      type: String,
      default: ''
    }
  },
  methods: {
    closeMask() {
      this.$emit('handleChange', false)
    },
    // hrefClick() {
    //   // window.location.href="https://"+this.url;//当前标签页
    //   // window.location.href=this.url;//当前标签页
    //   if (this.url.substr(0, 7).toLowerCase() == "http://" || this.url.substr(0, 8).toLowerCase() == "https://") {
    //     window.open(this.url)//打开一个新的标签页
    //   } else {
    //     window.open("https://"+this.url)
    //   }
    // },
    showEwm() {
      this.showEwmOrNot = true
    },
    hideEwm() {
      this.showEwmOrNot = false
    },

    clickToSoN() {
      this.showEwmOrNot = !this.showEwmOrNot
    },
    closeDialogMask(){
      this.showEwmOrNot = false
    },

    tanShow() {
      this.tan = !this.tan
    },
    leave() {
      this.sureToLeave = true
      this.tan = false
    },
    dontLeaveAndOpen(){
      this.sureToLeave = false
      this.tan = false
      this.showEwmOrNot = true
    },
    dontLeave() {
      this.sureToLeave = false
      this.tan = false
    },

  },
  created() {
    let that = this
    document.onkeydown = function (e) {
      let key = e.keyCode;
      if (key == 27) {
        that.closeMask()
      }
    }
  },
  mounted() {
    // this.showMask = this.value
  },
  watch:{
    'sureToLeave':function(nV){
      if(nV === true){
        if (this.url.substr(0, 7).toLowerCase() == "http://" || this.url.substr(0, 8).toLowerCase() == "https://") {
          window.open(this.url)//打开一个新的标签页
        } else {
          window.open("https://"+this.url)
        }
      }else{
        console.log("2222")
      }
    }
  }
}
</script>

<style scoped>
.dialog {
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  position: relative;
  padding: 4rem;
}

.icon, .icon img {
  /*max-width: 12rem;*/
  height: 12rem;
  /*background-color: #ccc;*/
}

.close {
  width: 2.6rem;
  height: 2.6rem;
  background: url("image/closeDialog.svg") center center no-repeat;
}

.close:hover {
  cursor: pointer;
}

.iconRight {
  width: 100%;
  /*margin-left: 24px;*/
}

.iconRightContainer {
  border-bottom: 0.1rem solid #dbdbdb;
}

.titleBigText {
  font-size: 2.4rem;
  line-height: 100%;
  color: #2A2E2E;
  margin: 2.7rem 0 1.6rem 0;
}

.textBig {
  font-size: 1.6rem;
  line-height: 100%;
  color: #333;
  font-weight: lighter;
  margin-bottom: 2.4rem;
}

.textBig a span {
  transition: 0.5s;
}

.link {
  display: block;
  /*margin-top: 24px;*/
  width: 100%;
  font-size: 1.6rem;
  color: #ff7d22;
  position: relative;
  margin-bottom: 2rem;
}

.dialogText {
  font-size: 1.6rem;
  line-height: 200%;
  margin-top: 1.6rem;
  max-height: 30rem;
  color: #5F6464;
  overflow: auto;
}

/*.dialogText p {*/
/*  font-size: 14px !important;*/
/*  line-height: 42px !important;*/
/*}*/

.titleSmall a:hover {
  cursor: pointer;
  color: #DF6917;
}

.ewm {
  display: block;
  position: fixed;
  border: 0 none !important;
  /*background-color: red;*/
  width: 20rem;
  height: 20rem;
  top: 20rem;
  left: calc(50% - 10rem);
  z-index:4;
}
.dialogMask{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0,0,0,0.3);
}
.dialogLeaveOrNotBox{
  width: 100%;
  height: 100vh;
  background-color: rgba(0,0,0,0.3);
  position:fixed;
  top: 0;
  left: 0;
}
.dialogLeaveOrNot{
  padding: 2rem 0;
  position: fixed;
  width: 32.7rem;
  height: 20rem;
  border-radius: 0.5rem;
  top: 20rem;
  left: 2.4rem;
  background-color: #fff;
  box-shadow: 0 0 1rem 0.5rem rgba(0,0,0,0.1);
  z-index:3;
}

.diaTitleBox{
  border-bottom: 0.1rem solid #f2f2f2;
}
.dialogInnerTitle{
  font-size: 2rem;
  color: #333;
  margin-left: 2rem;
  margin-bottom: 1rem;
}
.alertTitle{
  margin-left: 6rem;
  font-size: 1.6rem;
  margin-top: 2rem;
  position: relative;
}
.alertTitle:before{
  display: block;
  content: '!';
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  color: #fff;
  background-color: #ff7d22;
  position: absolute;
  left: -4rem;
  text-align: center;
  line-height: 2rem;
}
.alertBtnBox{
  margin-top: 3rem;
  margin-right: 2.4rem;
}
.alertSure{
  width: 5.4rem;
  height: 3rem;
  /*border: 0.1rem solid #f1f1f1;*/
  border-radius: 0.4rem;
  line-height: 3rem;
  text-align: center;
  background-color: #ff7d22;
  color: #fff;
  margin-left: 1rem;
}
.alertCancel{
  width: 5.4rem;
  height: 3rem;
  border: 0.1rem solid #f1f1f1;
  border-radius: 0.4rem;
  line-height: 3rem;
  text-align: center;
  margin-left: 1rem;
}
</style>