<template>
  <div v-show="isShow" class="scrollTop" @click="backtop">
    <div class="arr arr1"></div>
    <div class="arr arr2"></div>
  </div>
</template>

<script>
export default {
  name: "ScrollTop",
  data() {
    return {
      isShow: false
    }
  },
  mounted() {
    window.addEventListener('scroll', this.showbtn, true)
  },
  methods: {
    showbtn() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop < 660) {
        this.isShow = false
      } else {
        this.isShow = true
      }
      // console.log(this.isShow)
    },
    backtop() {
      var timer = setInterval(function () {
        let isTop = document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-isTop / 2);
        document.documentElement.scrollTop = document.body.scrollTop = isTop + ispeed;
        this.isTop = true;
        if (isTop === 0) {
          clearInterval(timer);
        }
      }, 30)
    }
  }
}
</script>

<style scoped>
.scrollTop {
  width: 60px;
  height: 60px;
  position: fixed;
  bottom: 80px;
  right: 80px;
  border: 1px #ff7d22 solid;
  border-radius: 50%;
  transition: all 0.5s;
  z-index: 10;
}

.scrollTop .arr {
  /*display: block;*/
  width: 16px;
  height: 12px;
  position: absolute;
  left: 23px;
  top: 25px;
}

.arr1 {
  z-index: 9999;
  background: url("image/arrWhite.svg") center center no-repeat;
  display: none;
  transition: all 0.5s;
}

.arr2 {
  z-index: 9998;
  background: url("image/arrOrange.svg") center center no-repeat;
  background-size: cover;
}

.scrollTop:hover {
  background-color: #ff7d22;
  border-color: #ff7d22;
  cursor: pointer;
}

.scrollTop:hover .arr1 {
  display: block;
}
</style>