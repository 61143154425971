<template>
  <div>
    <div class="moBannerOut moBannerInside boxSizing">
      <p class="moBannerTitle moTitle">加入我们</p>
    </div>
    <div class="reListBox boxSizing">
      <input type="button" @click="toMail" value="申请职位" class="offerBtn" :data-clipboard-text="emailAdd"/>
      <ul>
        <li v-for="(item,index) in recList" :key="item.id" class="boxSizing">
          <router-link :to="{path:'/moRecruitInner',query:{index:index}}">
            <p class="bigTitle">{{item.title}}</p>
            <p class="smallTitle">{{item.subTitle}}</p>
            <p class="dateTitle">{{item.createTime}}</p>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="tan" v-show="tan === true">{{ tanText }}</div>
  </div>
</template>

<script>
import axios from "axios";
import ClipboardJS from "clipboard";

export default {
  name: "MoRecruit",
  data(){
    return{
      currentIndex: 0,
      listBoxState: true,//点击导航栏时，暂时停止监听页面滚动
      leftFixed:false,
      recList:[],
      isBtm:false,
      emailAdd:'',
      tan:false,
      tan1:'已复制邮箱至剪贴板',
      tan2:'暂时不支持复制',
      tanText:''
    }
  },
  mounted() {
    this.getRecList()
    this.getTopInfo()
  },
  methods:{
    getRecList(){
      axios.get('/recruit/list').then(
          response => {
            // console.log(response.data)
            this.recList = response.data
            // this.newsList = this.newsListBig.slice(0,5)
          },
          error => {
            console.log(error.message)
          }
      )
    },
    getTopInfo() {
      axios.get('/companyInfo/list').then(
          response => {
            // console.log("asdasdsadasdas",response.data[0])
            this.topInfo = response.data[0]
            this.emailAdd = this.topInfo.email
          },
          error => {
            console.log(error.message)
          }
      )
    },
    toMail () {
      let clipboard = new ClipboardJS('.offerBtn')
      clipboard.on('success', () => {
        this.tan = true
        this.tanText = this.tan1
        //  定时消失
        setTimeout(() => {
          this.tan=false;
        },3000);
        //  释放内存
        clipboard.destroy()
      })
      clipboard.on('error', () =>{
        // 不支持复制
        this.tan = true
        this.tanText = this.tan2
        //  定时消失
        setTimeout(() => {
          this.tan=false;
        },3000);
        // 释放内存
        clipboard.destroy()
      })
    },
  }
}
</script>

<style scoped>
.moBannerInside{
  background-image: url("./image/recruitBg.jpg");
  /*margin-bottom: 3rem;*/
}
.moBannerTitle{
  margin: 0;
  text-align: left;
  padding-left: 2.4rem;
  color: #fff;
  font-size: 2.8rem;
  font-weight: lighter;
}
.reListBox{
  width: 37.5rem;
  padding: 2.4rem;
  background-color: #F5F5F5;
}
.reListBox li{
  width: 100%;
  background-color: #fff;
  margin-bottom: 2rem;
  padding: 2rem;
}
.bigTitle{
  font-size: 1.8rem;
  color: #333;
  line-height: 100%;
  margin-bottom: 2rem;
  font-weight: 500;
}
.smallTitle,.dateTitle{
  font-size: 1.4rem;
  color: #333;
  line-height: 100%;
  margin-bottom: 0.8rem;
}
.dateTitle{
  margin: 0;
}
.offerBtn{
  display: block;
  width: 100%;
  border: 0 none;
  background-color: #ff7d22;
  color: #fff;
  border-radius: 0.4rem;
  line-height: 5rem;
  margin-bottom: 2rem;
}

.tan{
  position: fixed;
  width: 20rem;
  height: 4rem;
  line-height: 4rem;
  top: 25rem;
  left: calc(50% - 10rem);
  background-color: rgba(0,0,0,0.2);
  border-radius: 0.4rem;
  color: #fff;
  text-align: center;
  transition: 0.3s;
}
</style>