<template>
  <div class="bigBox">
    <div class="banner">
      <div class="bannerMain">
        <p>企业资质</p>
      </div>
    </div>
    <div class="quaListBox">
      <ul class="quaList clear">
        <li v-for="item in quaInfo" :key="item.id" class="fl">
          <img class="listImg" :src="baseUrl+item.img"/>
          <p class="listTitle" v-html="item.description"></p>
        </li>
      </ul>
    </div>

  </div>
</template>

<script>
import axios from "axios";
import global from "@/config/global";

export default {
  name: "qualiFication",
  data(){
    return {
      quaInfo:'',
      baseUrl:global.BASE_URL
    }
  },
  mounted() {
    this.getQuaInfo()
  },
  methods:{
    getQuaInfo() {
      axios.get('/qualification/list').then(
          response => {
            // console.log(response.data)
            this.quaInfo = response.data
          },
          error => {
            console.log(error.message)
          }
      )
    },

  }
}
</script>

<style scoped>
.banner {
  width: 100%;
  height: 560px;
  background: url("image/quaBg.jpg") center bottom no-repeat;
  /*background-color: #333;*/
  background-size: cover;
}

.bannerMain {
  width: 1200px;
  position: relative;
  margin: 0 auto;
}

.bannerMain > p {
  font-size: 52px;
  font-style: italic;
  display: inline-block;
  width: 1200px;
  position: absolute;
  top: 240px;
  color: #ffffff;
  font-weight: bold;
}
.quaListBox{
  background-color: #fff;
}
.quaList{
  width: 1200px;
  margin: 0 auto;
  padding: 48px 0;
}
.quaList li{
  width: 383px;
  margin-right: 24px;
  margin-bottom: 36px;
}
.quaList li:nth-child(3n){
  margin-right: 0;
}
.listImg{
  height: 192px;
  max-width: 383px;
  display: block;
  border:0 none;
  margin: 0 auto;
  background-color: #dbdbdb;
}
.listTitle{
  text-align: center;
  line-height: 48px;
  font-size: 16px;
}
</style>