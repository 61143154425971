<template>
  <div>
    <div class="banner boxSizing">
      <p class="bannerBig">关于合友</p>
      <p class="bannerSmall">天作之合，友如星月</p>
    </div>
    <div class="titleNavContainer boxSizing" :class="navFixed2 === true?'isFixed2':''">
      <ul class="titleNavBox clear">
        <li v-for="i in titleList" :key="i.id" class="titleNav fl" @click="clickToSw(i.methods)">{{i.name}}</li>
      </ul>
    </div>
    <div class="about1 moContainer boxSizing">
      <p class="moTitle">关于合友</p>
      <p class="about1Text">
        <span class="textBlack">{{ topInfo.strStrong }}</span><br/><br/>
        {{ topInfo.str }}
      </p>
    </div>
    <div id="pro1">
      <ul class="udText clear moContainer boxSizing">
        <li class="fl boxSizing moContainer boxSizing" v-for="item in aboutUsMiddle" :key="item.id">
          <p class="udTextTitleCh">{{ item.small_title }}</p>
          <p class="udTextTitleEn">{{ item.english_title }}</p>
          <p class="udTextText" v-html="item.content"></p>
        </li>
      </ul>
    </div>
    <div id="pro2" class="history">
      <div class="historyBg boxSizing">
        <div class="historyBtn">
          <ul id="historyBtnBox" class="clear">
            <li v-for="(item,index) in historyList" :key="item.id" @click="getIndex(index)" class="fl">
              <p>{{ item.year }}</p>
              <div class="historyBtnDetail boxSizing">
                <div :class="index == current?'activeHistory':'normalHistory'"></div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="historyText boxSizing">
        <ul class="clear" id="historyBox" :style="marginLeft">
          <li v-for="item in historyList" :key="item.id" class="fl">
            <p class="yearText">{{ item.year }}</p>
            <ul>
              <li v-for="i in item.things" :key="i.id" class="monthHistory">
                {{ i.month }}月-{{ i.title }}
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div id="pro3" class="mainProductBox moContainer boxSizing">
      <div class="mainProductContainer">
        <p class="mainProductTitle moTitle gray">主要产品</p>
        <p class="mainProductText">合友公司旨在为客户提供优质服务，为客户赋能，提高工作效率，桥接各部门，让工作更有序，更高效，更便捷。</p>
        <ul class="mainProduct clear">
          <li v-for="item in product" :key="item.id" class="fl clear" @click="showDialog(item)">
            <div class="productIcon fl">
              <img :src="baseUrl+item.icon" alt="111" />
            </div>
            <div class="productTextMainPage fl">
              <p class="productTitle textOver1">{{ item.name }}</p>
              <p class="productText textOver1">{{ item.subTitle }}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div id="pro4" class="clear">
      <div class="cultureBox">
        <img :src="require('./image/culture.jpg')">
        <div class="cultureTextBox boxSizing">
          <p class="moTitle leftMoTitle">{{ lastAbout.title}}</p>
          <p class="cultureUdTitle orange">内涵有三</p>
          <p v-html="lastAbout.content" class="cultureTextP"></p>
          <p class="udCultureText orange">天作之<span class="udCultureTextBig">合</span>,<span
              class="udCultureTextBig">友</span>如星月</p>
        </div>
      </div>
    </div>
    <div id="pro5" class="addressBox">
      <div class="address">
      </div>
      <MoMyMap
          :address="topInfo.address"
          :email="topInfo.email"
          :latY="Number(topInfo.latY)"
          :lngX="Number(topInfo.lngX)"
          :tel="topInfo.tel"
          v-if="Number(topInfo.latY)&&Number(topInfo.lngX) != ''">
      </MoMyMap>
    </div>
    <div class="maskBg" v-show="showDialog11" @touchmove.prevent @mousewheel.prevent>
      <MoDiaLog :showOrNot="showDialog11"
                :title="title"
                :text="text"
                :content="content"
                :url="url"
                :picture="picture"
                :code="code"
                @handleChange="changeShowOrNot"
                v-show="showDialog11">
      </MoDiaLog>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import global from "@/config/global";
import MoDiaLog from "@/components/Mobile/MoDialog/MoDialog";
import MoMyMap from "@/components/Mobile/MoMyMap/MoMyMap";

export default {
  name: "MobileAbout",
  components: {MoMyMap, MoDiaLog},
  data(){
    return{
      titleList:[
        {id:'01',name:'公司简介',methods:'toCompany'},
        {id:'02',name:'公司事记',methods:'toThings'},
        {id:'03',name:'主营产品',methods:'toProduct'},
        {id:'04',name:'何谓合友',methods:'toCulture'},
        {id:'05',name:'公司地点',methods:'toAddress'},
      ],
      topInfo:'',
      aboutUsMiddle:'',
      aboutUsInfo:'',
      lastAbout:'',
      aboutUsSmall:'',
      oldHistoryList:'',
      historyList:'',
      current:'',
      marginLeft:'',
      oldProList:'',
      product:'',
      baseUrl:global.BASE_URL,
      showDialog11: false,
      title:'',
      text:'',
      content:'',
      picture:'',
      code:'',
      url:'',
      tof:'',
      navFixed2:false
    }
  },
  mounted() {
    this.baseUrl = global.BASE_URL
    // console.log(this.baseUrl)
    window.addEventListener('scroll', this.handleScroll2)
    this.getTopInfo()
    this.getAboutInfo()
    this.getHistoryList()
    this.getProList()
  },
  methods:{
    getTopInfo() {
      axios.get('/companyInfo/list').then(
          response => {
            // console.log("asdasdsadasdas",response.data[0])
            this.topInfo = response.data[0]
          },
          error => {
            console.log(error.message)
          }
      )
    },
    getAboutInfo() {
      axios.get('/points_img/queryPointsList').then(
          response => {
            // console.log(response.data)
            this.aboutUsInfo = response.data
            this.lastAbout = this.aboutUsInfo[this.aboutUsInfo.length-1]
            let oldArr = response.data
            let newArr = []
            for(let i = 0;i < oldArr.length;i++){
              if(oldArr[i].sort == '2'){
                newArr.push(oldArr[i])
              }
            }
            this.aboutUsMiddle = newArr
            this.aboutUsSmall = newArr.slice(0,2)
          },
          error => {
            console.log(error.message)
          }
      )
    },
    getHistoryList() {
      axios.get('/photo/queryPhotoList').then(
          response => {
            // console.log(response.data)
            this.oldHistoryList = response.data
            function compare(property){
              return function (a,b){
                let value1 = a[property]
                let value2 = b[property]
                return value2-value1
              }
            }
            this.historyList = this.oldHistoryList.sort(compare('year'))
          },
          error => {
            console.log(error.message)
          }
      )
    },
    getIndex(index) {
      this.current = index
      // console.log(this.current)
      this.marginLeft = 'margin-left:'+ -this.current * 27.4 + 'rem'
      // console.log(this.marginLeft)
    },
    getProList() {
      axios.get('/product/list').then(
          response => {
            this.oldProList = response.data
            function compare(property){
              return function (a,b){
                let value1 = a[property]
                let value2 = b[property]
                return value1-value2
              }
            }
            this.product = this.oldProList.sort(compare('sort'))
          },
          error => {
            console.log(error.message)
          }
      )
    },
    showDialog(value) {
      this.showDialog11 = true
      // console.log(value)
      this.title = value.name
      this.text = value.subTitle
      this.content = value.description
      this.picture = this.baseUrl+value.icon
      this.code = value.code
      this.url = value.url
      this.navFixed2 = false
    },
    changeShowOrNot(tof) {
      this.showDialog11 = tof
      // console.log(this.showDialog11)
    },
    clickToSw(i){
      this[i]();
    },
    toCompany() {
      document.getElementById("pro1").scrollIntoView({behavior: "smooth"})
    },
    toThings() {
      document.getElementById("pro2").scrollIntoView({behavior: "smooth"})
    },
    toProduct() {
      document.getElementById("pro3").scrollIntoView({behavior: "smooth"})
    },
    toCulture() {
      document.getElementById("pro4").scrollIntoView({behavior: "smooth"})
    },
    toAddress() {
      document.getElementById("pro5").scrollIntoView({behavior: "smooth"})
    },

    handleScroll2() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      let offsetTop = document.querySelector(".titleNavBox").clientHeight
      // console.log('scrollTop',scrollTop)
      // console.log('offsetTop',offsetTop)
      let scroll = scrollTop - this.i;
      this.i = scrollTop;
      if(scroll>0 && scrollTop > (offsetTop + 600)){
        let navAbout = document.querySelector(".titleNavContainer")
        this.navFixed2 = true
        navAbout.style.top = '0'
      }else if( scrollTop < (offsetTop + 600)){
        let navAbout = document.querySelector(".titleNavContainer")
        this.navFixed2 = false
        navAbout.style.top = '0'
      }else{
        let navAbout = document.querySelector(".titleNavContainer")
        navAbout.style.top = '-5rem'
      }
      // if (scrollTop > (offsetTop + 700)) {
      //   // console.log('true')
      //   this.navFixed2 = true
      // } else {
      //   this.navFixed2 = false
      // }
    },
  },
  beforeDestroy() {
    window.removeEventListener('scroll',this.handleScroll2)
  }
}
</script>

<style scoped>
.banner{
  background: url("./image/banner.png") center center no-repeat;
  background-size: cover;
  width: 100%;
  height: 39rem;
  padding: 17.5rem 2.4rem 0 2.4rem;
}
.bannerBig{
  font-size: 2.8rem;
  color: #fff;
  font-weight: 400;
  line-height: 100%;
}
.bannerSmall{
  font-size: 1.6rem;
  color: #fff;
  line-height: 100%;
  margin-top: 1.2rem;
}
.titleNavContainer{
  background-color: #fff;
  padding: 0 0 0 2.4rem;
  width: 100%;
  overflow-x: auto;
  transition: 0.5s;
}
.titleNavBox{
  width: 127%;
  /*width: auto;*/
  background-color: #fbfbfb;
  border-bottom: 0.1rem solid #ededed;
}
.titleNav{
  font-size: 1.4rem;
  line-height: 4rem;
  margin-left: 3.6rem;
}
.titleNav:first-child{
  margin-left: 0;
}

.isFixed2 {
  position: fixed;
  z-index: 4;
  background-color: #fff;
  width: 100%;
  border-bottom: 0.1rem solid #ededed;
  top: 0;
  left: 0;
}

::-webkit-scrollbar {
  /*display: none;*/
  /*滚动条整体样式*/
  /*width : 10px;  !*高宽分别对应横竖滚动条的尺寸*!*/
  width: 0.2rem;
  height: 0.3rem;
}
::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius   : 10px;
  background-color: #ff7d22;
}
::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.1);
  background   : #eeeeee;
  border-radius: 10px;
}
.textBlack{
  font-size: 1.6rem;
  line-height: 2.8rem;
  font-weight: 500;
  color: #333;
}
.about1{
  text-align: justify;
}
.about1Text{
  margin-top: 1.8rem;
  font-size: 1.6rem;
  line-height: 2.8rem;
}

/*关于三块儿*/
.udText{
  margin-top: 1rem;
}
.udText li{
  background-color: #f6f6f6;
  margin-top: 2rem;
  padding-bottom: 4rem;
}

.udTextTitleCh{
  font-size: 2rem;
  font-weight: 500;
  color: #333;
  font-style: italic;
  line-height: 2rem;
  text-align: center;
  margin-top: 3.2rem;
}
.udTextTitleEn{
  font-size: 1.2rem;
  margin-top: 1.8rem;
  color: rgba(128,128,128,0.25);
  text-align: center;
}
.udTextText{
  font-size: 1.6rem;
  line-height: 2.8rem;
  color: #808080;
  text-align: justify;
  margin-top: 1.8rem;
  font-weight: lighter;
}

.history{
  margin-top: 3.5rem;
}
.historyBg{
  background:url("./image/historyBg.jpg") center center no-repeat;
  background-size: cover;
  width: 100%;
  height: 13.8rem;
  /*padding-top: 6.6rem;*/
}
.historyBtn{
  background:url("./image/historyLine.svg") center bottom no-repeat;
  /*background-size: cover;*/
  width: 29.39rem;
  height: 0.2rem;
  padding-top: 6.6rem;
  margin: 0 auto;
}

.historyBtn {
  position: relative;
}

.historyBtn > ul > li {
  position: absolute;
  font-size: 1.4rem;
  line-height: 100%;
  color: #ffffff;
}

.historyBtn > ul > li{
  top: -2.7rem;
  left: 0;
  z-index: 2;
}
.historyBtn > ul > li:first-child{
  left: -2.1rem;
}
.historyBtn > ul > li:nth-child(2){
  left: 3.95rem;
}
.historyBtn > ul > li:nth-child(3){
  left: 10.05rem;
}
.historyBtn > ul > li:nth-child(4){
  left: 16.15rem;
}
.historyBtn > ul > li:nth-child(5){
  left: 22.25rem;
}
.historyBtn > ul > li:nth-child(6){
  left: 28.35rem;
}
.historyBtnDetail {
  display: block;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 0.6rem;
  margin: 0.8rem auto;
  background-color: rgba(255,255,255,0.2);
  padding: 0.2rem;
}

.historyBtnDetail .normalHistory{
  display: block;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 0.4rem;
  background-color: rgba(255,255,255,0.5);
  transition: 0.3s;
}

.historyBtnDetail .activeHistory{
  display: block;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 0.4rem;
  background-color: rgba(255,255,255,1);
  transition: 0.3s;
}
.historyText{
  width: 35.1rem;
  margin: 2.4rem 0 5rem 2.4rem;
  overflow-x: auto;
  padding-bottom:2.4rem;
}
.historyText>ul{
  width: 200rem;
  transition: 0.5s;
}
.historyText>ul>li{
  width: 25rem;
  margin-right: 2rem;
}
.historyText>ul>li>p{
  width: 100%;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 600;
  color: #333;
  padding-bottom: 1.2rem;
  font-style: italic;
  border-bottom: 0.1rem solid #dbdbdb;
}
.historyText>ul>li>p.yearText{
  font-size: 2.4rem;
}
.monthHistory{
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-top: 1.8rem;
}

/*我们的产品*/
.mainProductBox{

}
.mainProductText{
  font-size: 1.6rem;
  line-height: 2.8rem;
  margin-top: 2.4rem;
}
.mainProduct li{
  width: 100%;
  margin: 1.8rem 0;
}
.productIcon{
  height: 6rem;
  width: 9.3rem;
}
.productIcon img{
  max-height: 6rem;
  max-width: 9.3rem;
}
.productTitle{
  font-size: 1.8rem;
  line-height: 3rem;
  color: #333;
  font-weight: 500;
  margin-top: 0.3rem;
}
.productText{
  font-size: 1.6rem;
  line-height: 2.1rem;
  color: #5c5c5c;
}

/*弹框*/
.maskBg{
  width: 37.5rem;
  height: 100vh;
  background-color: rgba(0,0,0,0.3);
  position: fixed;
  top: 0;
  left: 0;
  z-index:3;
}

.leftMoTitle{
  text-align: left;
  margin-top: 3rem;
}
.cultureTextBox{
  width: 100%;
  padding: 2.4rem 2.4rem 3rem 2.4rem;
}
.cultureUdTitle{
  font-size: 1.6rem;
  line-height: 100%;
  margin: 2.8rem 0 3.2rem 0;
}
.cultureTextP{
  font-size: 1.6rem;
  line-height: 3.8rem;
  color: #333;
  font-weight: 400;
  margin-bottom: 2rem;
}

.udCultureText{
  font-size: 1.6rem;
}
.udCultureTextBig{
  font-size: 2.4rem;
}
.cultureBox{
  margin-top: 3rem;
}
</style>