<template>
  <div class="bigBox boxSizing">
    <div class="newsBox">
      <div class="newsTitleBox">
        <p class="newsTitle">{{ info.title }}</p>
        <p class="newsDate">{{ info.create_time }}</p>
      </div>
      <div class="textBox">
        <p ref="contentText" class="text" v-html="contentText"></p>
      </div>
    </div>
  </div>
</template>

<script>
import global from "@/config/global";
import axios from "axios";

export default {
  name: "MoNewsInner",
  data() {
    return {
      title: '',
      date: '',
      info: '',
      baseUrl: global.BASE_URL_2,
      contentText: ''
    }
  },
  methods: {
    getNewsInfo() {
      axios.get(`/journalism/queryNewsInfo?id=${this.$route.query.id}`).then(
          response => {
            this.info = response.data
            // console.log(response.data)
            let contentText = response.data.content;  //从response获取HTML的数据
            // let srcReg = /<\/?(img)[^>]*>/gi;
            // if(contentText){
            //   contentText = contentText.replace(srcReg,"src='"+this.baseUrl+"$2"+"'");
            //   console.log(contentText)
            //   this.html = contentText;
            // }
            contentText= contentText.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi, (match, p1) => {

              return  `<img style="max-width: 100%;margin: 24px auto;" src='${this.baseUrl+p1}' />`
            })
            this.contentText = contentText
            // console.log(contentText)
          },
          error => {
            console.log(error.message)
          }
      )
    },

  },
  mounted() {
    // console.log(this.$route.query.id)
    this.getNewsInfo(
    )
    // console.log(this.$route.query.text)
    // this.title= this.$route.query.title
    // this.date= this.$route.query.date
  }
}
</script>

<style scoped>
.bigBox{
  width: 100%;
  padding: 10.4rem 2.4rem 2.4rem 2.4rem;
}
.newsTitle{
  font-size: 2.4rem;
  line-height: 3.6rem;
}
.newsDate{
  font-size: 1.4rem;
  line-height: 5.2rem;
  color: #999;
}
.newsTitleBox{
  border-bottom: 0.1rem solid #eaeaea;
}
.textBox{
  margin-top: 1.6rem;
}
.text /deep/ .MsoNormal{
  font-size: 1.6rem!important;
  line-height: 3.2rem!important;
  color: #333!important;
}
</style>