<template>
  <div id="app">
    <div v-if="isMobile === false">
      <HeadEr></HeadEr>
      <router-view></router-view>
      <ScrollTop></ScrollTop>
      <FootEr></FootEr>
    </div>
    <div v-if="isMobile === true">
      <MobileHead></MobileHead>
      <router-view></router-view>
      <MobileFoot></MobileFoot>
    </div>
<!--    <HeadEr></HeadEr>-->
<!--    <router-view></router-view>-->
<!--    <ScrollTop></ScrollTop>-->
<!--    <FootEr></FootEr>-->
  </div>
</template>

<script>


import FootEr from "@/components/PC/Footer/Footer";
import ScrollTop from "@/components/PC/ScrollTop/ScrollTop";
import HeadEr from "@/components/PC/Header/Head";
import MobileHead from "@/components/Mobile/MobileHead/MobileHead";
import MobileFoot from "@/components/Mobile/MobileFoot/MobileFoot";

export default {
  name: 'App',
  components: {MobileFoot, MobileHead, HeadEr, ScrollTop, FootEr},
  data(){
    return {
      isMobile:''
    }
  },
  metaInfo(){
    return {
      meta:[
        {
          name:'viewport',
          content:'width=device-width,user-scalable = no,initial-scale = 1.0,maximum-scale = 1.0,minimum-scale =1.0'
        }
      ]
    }
  },
  mounted() {
    if(this._isMobile()){
      this.isMobile = true
    }else{
      this.isMobile = false
    }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match( // match方法可在字符串内检索指定的值，
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      // console.log(flag)
      return flag
      // console.log(this.flag)
      // return flag;
    }
  },
}
</script>
<style>

</style>
