<template>
  <div class="productBox">
    <div class="productContainer clear">
      <div class="productLeft boxSizing fl">
        <div class="productTitle">
          <p class="titleEn"><span>O</span>UR PRODUCTS FOR YOU.</p>
          <p class="titleCh"><span>我</span>们为您提供的服务</p>
        </div>
        <p class="productText" v-html="topInfo.serviceDescript">
        </p>
      </div>
      <ul class="productRight fl clear">
        <li class="productList p1 fl" v-for="item in product" :key="item.id">
          <div class="blackMask"></div>
          <router-link to="/about#pro5">
            <div>
              <span class="textOver1">{{ item.name }}</span>
              <span class="textOver1" v-html="item.description"></span>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from "axios";


export default {
  name: "OurProductIndex",
  data() {
    return {
      topInfo: '',
      product: ''
    }
  },
  methods: {
    getTopInfo() {
      axios.get('/companyInfo/list').then(
          response => {
            // console.log(response.data)
            this.topInfo = response.data[0]
          },
          error => {
            console.log(error.message)
          }
      )
    },
    getProList() {
      axios.get('/product/list').then(
          response => {
            this.product = response.data.slice(0, 4)
            // console.log("23232323232",this.product)
          },
          error => {
            console.log(error.message)
          }
      )
    },

  },
  mounted() {
    this.getTopInfo()
    this.getProList()
  }
}
</script>

<style scoped>
.productBox {
  background-color: #1b1f25;
}

.productContainer {
  width: 1200px;
  margin: 0 auto;
}

.productLeft {
  width: 414px;
  padding: 60px 90px 151px 0;
}

.productLeft {
  color: #ffffff;
}

.productTitle {
  margin-bottom: 38px;
}

.titleEn {
  font-family: "Gill Sans";
  font-size: 24px;
}

.titleEn span {
  font-size: 48px;
}

.titleCh {
  font-size: 24px;
}

.titleCh span {
  font-size: 36px;
}

.productText {
  font-size: 16px;
  line-height: 36px;
}

.productRight {
  width: 786px;
}

.productList {
  width: 393px;
  height: 285px;
  background-size: cover;
  position: relative;
}

.productRight li{
  position: relative;
}
.blackMask{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}
.productRight li:first-child {
  background: url("image/p1.jpg") center center no-repeat;
}

.productRight li:nth-child(2) {
  background: url("image/p2.jpg") center center no-repeat;
}

.productRight li:nth-child(3) {
  background: url("image/p3.jpg") center center no-repeat;
}

.productRight li:nth-child(4) {
  background: url("image/p4.jpg") center center no-repeat;
}
.productList a{
  display: block;
  width: 100%;
  height: 100%;
}
.productList a div{
  color: #fff;
  position: absolute;
  bottom: 18px;
  left: 18px;
  display: inline-block;
  max-width: 100%;
  height: 22px;
  overflow: hidden;
}

.productList a div span {
  transition: 0.2s;
  width: 100%;
  display: block;
  padding-right: 24px;
  box-sizing: border-box;
}

.productList a div span:first-child{
  font-size: 22px;
  line-height: 22px;
}

.productList a div span:nth-child(2){
  font-size: 14px;
  line-height: 22px;
}

.productRight:hover {
  cursor: pointer;
}

.productList:hover div span:first-child {
  margin-top: -24px;
}
</style>