<template>
  <div class="banner">
    <div class="bannerBox">
      <div class="bannerContainer">
        <p class="bannerTitle">智慧化引领，科技化驱动</p>
        <p class="bannerText">合友科技，旨在助力企业成长，科技化的现代管理模式</p>
      </div>
    </div>
    <div style="background-color: rgba(0,0,0,0.4);width: 100%;height: 720px;position: absolute;z-index: 8"></div>
    <video-player class="video-player vjs-custom-skin my-video"
                  ref="videoPlayer"
                  :playsinline="true"
                  :options="playerOptions"
    >
    </video-player>
  </div>
</template>

<script>
export default {
  name:'BannEr',
  data(){
    return {
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: true, // 如果为true,浏览器准备好时开始回放。
        muted: true, // 默认情况下将会消除任何音频。
        loop: true, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: false, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: "video/mp4", // 类型
          src: require("../../../assets/indexVideo.mp4") // url地址
        }],
        poster: '', // 封面地址
        height:"720px",
        width:document.getElementById.clientWidth,
        notSupportedMessage: ' ', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: false
      }
    }
  },
  computed:{
    player(){
      return this.$refs.videoPlayer.player
    }
  }
}
</script>

<style scoped>
.vjs-custom-skin > .video-js .vjs-big-play-button{
  display: none!important;
}
.video-player{
  /*margin-top: -146px!important;*/
}
/*banner*/
.banner{
  width: 100%;
  height: 720px;
  /*background:url("./banner.jpg") center center no-repeat;*/
  /*background-size: cover;*/
  overflow: hidden;
}
/*.my-video{*/
/*  width: 100%;*/
/*  height: 720px;*/
/*}*/
.my-video .vjs-modal-dialog-content{
  padding: 0!important;
}
.my-video .vjs-big-play-button{
  display: none;
}
.bannerBox{
  width: 100%;
  position: absolute;
  z-index: 10;
}
.bannerContainer{
  width: 1200px;
  margin: 0 auto;
}
/*.banner image{*/
/*  display: block;*/
/*  border: 0 none;*/
/*  width: 100%;*/
/*  height: 790px;*/
/*}*/
.bannerTitle{
  font-size: 48px;
  font-weight: bold;
  padding-top: 250px;
}
.bannerText{
  font-size: 18px;
  margin-top: 24px;
}
.bannerTitle,.bannerText{
  line-height: 100%;
  color: #fff;
}
.video:focus{
  outline: 0;
}
.video-js{
  z-index:2!important;
}
</style>