<template>
  <div class="porIndexBox">
    <div>
      <p class="titleEn"><span>O</span>UR PRODUCTS FOR YOU.</p>
      <p class="titleCh">我们为您提供的服务</p>
      <div class="udTitle"></div>
      <p v-html="topInfo.serviceDescript" class="mainText"></p>
    </div>
    <ul class="productRight">
      <li v-for="item in product" :key="item.id" class="boxSizing">
        <div class="aboveLi"></div>
        <p class="proTitle">{{item.name}}</p>
        <p v-html="item.description.replace(/<[^>]+>/g,'')" class="textOver3 proMain"></p>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "MoIndexPro",
  data(){
    return{
      topInfo:'',
      product:[]
    }
  },
  methods: {
    getTopInfo() {
      axios.get('/companyInfo/list').then(
          response => {
            // console.log(response.data)
            this.topInfo = response.data[0]
          },
          error => {
            console.log(error.message)
          }
      )
    },
    getProList() {
      axios.get('/product/list').then(
          response => {
            this.product = response.data.slice(0, 4)
            // console.log("23232323232",this.product)
          },
          error => {
            console.log(error.message)
          }
      )
    },

  },
  mounted() {
    this.getTopInfo()
    this.getProList()
  }
}
</script>

<style scoped>

.porIndexBox{
  background-color:#25221B;
  padding: 4.8rem 2.4rem 2rem 2.4rem;
}
.titleEn{
  font-family: "Gill Sans";
  font-size: 1.6rem;
  color: #fff;
  font-weight: lighter;
  line-height: 100%;
  opacity: 0.35;
}
.titleEn span{
  font-size: 2.4rem;
  font-weight: 400;
}
.titleCh{
  font-size: 2.4rem;
  color: #fff;
  margin: 0.8rem 0 1.6rem;
  line-height: 100%;
}
.udTitle{
  width: 6.2rem;
  height: 0.2rem;
  background-color: #fff;
}
.mainText{
  font-size: 1.6rem;
  line-height: 200%;
  color: #fff;
  margin: 1.8rem 0;
  font-weight: lighter;
}

.productRight li{
  width: 100%;
  height: 28.5rem;
  position: relative;
  margin-top: 2rem;
}
.aboveLi{
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.6;
  position: absolute;
}

.productRight li p{
  color: #fff;
  margin-left: 2.4rem;
  max-width: calc(100% - 4.8rem);
  text-align: justify;
}

.productRight li .proTitle{
  font-size: 1.8rem;
  margin-bottom: 1.8rem;
  padding-top: 12.4rem;
}


.productRight li .proMain{
  font-size: 1.6rem;
  line-height: 2.4rem;
  max-height: 7.2rem;
  font-weight: lighter;
}

.productRight li:first-child {
  background: url("./image/p1.jpg") center center no-repeat;
}

.productRight li:nth-child(2) {
  background: url("./image/p2.jpg") center center no-repeat;
}

.productRight li:nth-child(3) {
  background: url("./image/p3.jpg") center center no-repeat;
}

.productRight li:nth-child(4) {
  background: url("./image/p4.jpg") center center no-repeat;
}
</style>