<template>
  <div class="bigEventsBox" @keyup.esc="closeMask">
    <div class="bigEventsContainer clear">
      <div class="bigEventsLeft fl">
        <p class="titleCh">
          <span class="titleEn">
            <i class="titleEnFirst">C</i>HRONICE OF EVENTS.
          </span>
          <i class="titleChFirst">合</i>友大事记
        </p>
        <ul>
          <li v-for="item in latestHis.things" :key="item.id">{{latestHis.year}}年{{ item.month }}月<span style="margin-left: 16px;">{{ item.title }}</span></li>
          <p>...</p>
        </ul>
      </div>
<!--      <router-link class="bigEventsRight fl" to="/about#pro2">-->
<!--        <p>科技引领，智慧服务</p>-->
<!--        <div class="arrBox">-->
<!--          <div class="arrTop"></div>-->
<!--          <div class="arrBottom"></div>-->
<!--        </div>-->
<!--      </router-link>-->
      <a class="bigEventsRight fl" @click="showVideo">
        <p>科技引领，智慧服务</p>
        <div class="arrBox">
          <div class="arrTop"></div>
          <div class="arrBottom"></div>
        </div>
      </a>
    </div>
    <div class="videoBigBox" v-if="showOrNot">
      <div class="videoBox">
        <p class="showSmall" v-if="showSmallOrNot">按ESC退出播放</p>
        <a class="close fr" @click="closeMask" title="或按ESC退出视频"></a>
        <video-player class="video-player vjs-custom-skin"
                      ref="videoPlayer"
                      :playsinline="true"
                      :options="playerOptions"
        >
        </video-player>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "BigEvents",
  data() {
    return {
      historyList: [],
      latestHis:'',
      showOrNot:'',
      playerOptions: {
        //播放速度
        playbackRates: [0.5, 1.0, 1.5, 2.0],
        //如果true,浏览器准备好时开始回放。
        autoplay: true,
        // 默认情况下将会消除任何音频。
        muted: false,
        // 导致视频一结束就重新开始。
        loop: false,
        // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        preload: 'auto',
        language: 'zh-CN',
        // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        aspectRatio: '16:9',
        // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        fluid: true,
        sources: [{
          //类型
          type: "video/mp4",
          //url地址
          src: require("../../../assets/eventsVideo.mp4")
        }],
        //你的封面地址
        poster: '',
        //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        notSupportedMessage: '此视频暂无法播放，请稍后再试',
        controls:true,
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          //全屏按钮
          fullscreenToggle: true,
          currentTimeDisplay: true, // 当前时间
          volumeControl: true, // 声音控制键
          // playToggle: true, // 暂停和播放键
          // progressControl: true, // 进度条

        }
      },
      showSmallOrNot:''
    }
  },
  mounted() {
    this.getHistoryList()
    let that = this
    document.onkeydown = function(e){
      let key = e.keyCode;
      if (key == 27){
        that.closeMask()
      }
    }
  },
  methods:{
    getHistoryList() {
      axios.get('/photo/queryPhotoList').then(
          response => {
            // console.log(response.data)
            this.oldHistoryList = response.data
            function compare(property){
              return function (a,b){
                let value1 = a[property]
                let value2 = b[property]
                return value1-value2
              }
            }
            this.historyList = this.oldHistoryList.sort(compare('year'))
            this.latestHis = this.historyList[this.historyList.length -1]
            // console.log("232323232",this.latestHis)
          },
          error => {
            console.log(error.message)
          }
      )
    },
    showVideo(){
      this.showOrNot = true
      this.showSmallOrNot = true
      setTimeout(() => {
        this.showSmallOrNot=false;
      },3000);
    },
    closeMask() {
      this.showOrNot = false
    },


  }
}
</script>

<style scoped>
.bigEventsBox {
  width: 100%;
  background-color: #fff;
  padding-bottom: 48px;
}

.bigEventsContainer {
  width: 1200px;
  margin: 0 auto;
  background-size: cover;
}

.bigEventsLeft {
  width: 831px;
  height: 337px;
  padding: 32px 44px;
  box-sizing: border-box;
  background: url("image/bebg.jpg") center center no-repeat;
}
.bigEventsLeft>ul{
  color: #fff;
}
.bigEventsLeft>ul>li{
  font-size: 16px;
  margin-bottom: 16px;
}
.bigEventsLeft>ul>p{
  margin-top: 36px;
}
.titleCh,.titleChFirst{
  color: #fff;
}
.titleCh {
  font-size: 24px;
  margin-bottom: 36px;
}

.titleChFirst {
  font-size: 36px;
}

.titleEn, .titleEnFirst {
  font-family: 'Gill Sans';
  color: #fff;
}

.titleEn {
  font-size: 24px;
}

.titleEnFirst {
  font-size: 48px;
}

.bigEventsRight {
  width: 345px;
  height: 337px;
  background: url("image/bebg2.jpg") center center no-repeat;
  margin-left: 24px;
  display: block;
}
.bigEventsRight p{
  font-size: 24px;
  line-height: 54px;
  display: inline-block;
  border-bottom: 1px solid #ffffff;
  color: #ffffff;
  margin-top: 130px;
  margin-left: 64px;
}
.arrBox{
  position: relative;
  margin-left: 140px;
  padding-top: 12px;
}
.arrTop,.arrBottom{
  position: absolute;
}
.arrTop{
  width: 12px;
  height: 6px;
  background: url("image/arrTop.svg") center center no-repeat;
  left: 26px;
}
.arrBottom{
  width: 58px;
  height: 29px;
  background: url("image/arrBottom.svg") center center no-repeat;
  top: 18px;
}
.bigEventsRight:hover .arrTop{
  margin-top: -80px;
  transition: 0.5s;
}
.bigEventsRight:hover{
  cursor: pointer;
}
.videoBigBox{
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
}
.videoBox{
  width: 1200px;
  height: 675px;
  position: absolute;
  top: calc(50% - 337px);
  left: calc( 50% - 600px);
  /*background-color: #DF6917;*/
  display: inline-block;
  text-align: center;
  line-height: 100px;
  border: 1px solid transparent;
  border-radius: 4px;
  /*overflow: hidden;*/
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  margin-right: 4px;
}
.videoBox:hover{
  display: block;
}
.close{
  display: block;
  width: 26px;
  height: 26px;
  background: url("image/closeDialog.svg") center center no-repeat;
  z-index: 1000;
  position: absolute;
  right: -60px;
  top: -60px;
  transition: 0.5s;
}
.close:hover{
  cursor: pointer;
  transform: rotate(180deg);
}

.vjs-custom-skin /deep/>.video-js {
  pointer-events: all!important;
}

.showSmall{
  width: 200px;
  line-height: 32px;
  background-color: rgba(255,255,255,0.2);
  z-index: 1000;
  position: absolute;
  top: -60px;
  left: calc(50% - 100px);
  border-radius: 2px;
  color: #ccc;
}

.vjs-custom-skin /deep/.vjs-volume-panel{
  display: none;
}

</style>