<template>
  <div class="outBox">
    <div class="news-title-box">
      <p class="news-title">合友动态</p>
    </div>
    <ul class="clear newsContainer">
      <li v-for="item in newsList" :key="item.id" :style="{backgroundColor:item.backgroud}" class="newItem fl">
        <router-link :to="{
          path: '/newsInner',
          query:{
            id:item.id
          }
        }">
          <div class="imgContainer">
            <div class="newsImg">
              <img :src="baseURL+item.path" :onerror="errorImg"/>
            </div>
          </div>
          <div class="newsMain">
            <div class="newDate boxSizing" :class="item.backgroud != '#ffffff' ?'whiteWords':''">{{ item.create_time.slice(0,11) }}</div>
            <div class="newTitle boxSizing" :class="item.backgroud != '#ffffff' ?'whiteWords':''">{{ item.title }}</div>
            <div class="newText boxSizing" v-html="item.content.replace(/<\/?(img)[^>]*>/gi,'').replace(/<[^>]+>/g,'')" :class="item.backgroud != '#ffffff' ?'whiteWords':''">
            </div>
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from "axios";
import global from "@/config/global";

export default {
  name: "MoIndexNews",
  data(){
    return{
      newsList:[],
      baseURL:global.BASE_URL,
      errorImg: 'this.src="' + require('../../../assets/errorImg.svg') + '"'
    }
  },
  methods: {
    getNewsList() {
      axios.get('/journalism/queryNewsList').then(
          response => {
            // console.log(response.data)
            this.newsListBig = response.data
            this.newsList = this.newsListBig.slice(0,5)
            let newTextArr = []
            let oldTextArr = response.data
            oldTextArr.forEach(e => {
              newTextArr.push(e.content)
            })
            this.newsContentList = newTextArr
            // console.log(this.newsContentList)
            // let re1 = new RegExp("<.+?>","g");
            // let msg = this.newsContentList[0].replace(re1,'')
            // var rowNum=Math.round(titleBox.offsetHeight/parseFloat(titleBox.css('line-height')));
            // console.log(msg)
            // console.log(rowNum)
          },
          error => {
            console.log(error.message)
          }
      )
    }
  },
  created() {
    this.getNewsList()
  },
}
</script>

<style scoped>
/*新闻标题*/
.news-title-box{
  width: calc(100% - 4.8rem);
  margin: -11.4rem auto 0 auto;
  /*border-top: 0.15rem solid #fff;*/
}
.news-title{
  font-size: 2.8rem;
  color: #fff;
  font-weight: 400;
  line-height: 100%;
  margin: 2rem 0 3.2rem 0;
}
/*新闻列表*/
.newsContainer{
  width: calc(100% - 4.8rem);
  margin: 0 auto;
}
.imgContainer{
  width: 100%;
  height: 21.8rem;
  overflow: hidden;
}
.imgContainer>.newsImg>img{
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.newsContainer .whiteWords{
  color: #fff!important;
}
.newsContainer li{
  margin-bottom: 2rem;
}
.newsContainer li .newText{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 1.6rem;
  line-height: 150%;
  color: #333;
  padding: 0 2.4rem;
  font-weight: normal;
}
.newsMain{
  padding: 2.4rem 0 4.8rem 0;
}
.newDate{
  display: inline-block;
  font-size: 1.4rem;
  padding: 0.8rem 2.6rem;
  border: 0.1rem solid;
  border-left: 0 none;
  margin-bottom: 2.4rem;
  color: #333;
}
.newsContainer .newTitle {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 2.4rem;
  padding: 0 2.4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height:6.4rem;
}
</style>