import VueRouter from 'vue-router'

import HomeMain from "@/pages/PC/MainPage/HomeMain";
import AboutMain from "@/pages/PC/AboutUs/AboutUs";
import NewsList from "@/pages/PC/NewsList/NewsList";
import MainPage from "@/pages/PC/MainPage/HomeMain";
import NewsInner from "@/pages/PC/NewsInner/NewsInner";
// import SomeThings from "@/pages/SomeThings/SomeThings";
import Qualification from "@/pages/PC/Qualification/Qualification"
import Recruit from "@/pages/PC/Recruit/Recruit";
import MobileIndex from "@/pages/Mobile/MobileIndex/MobileIndex";
import MobileAbout from "@/pages/Mobile/MobileAbout/MobileAbout"
import MoNewsList from "@/pages/Mobile/MoNewsList/MoNewsList";
import MoQualification from "@/pages/Mobile/MoQualification/MoQualification";
import MoNewsInner from "@/pages/Mobile/moNewsInner/MoNewsInner";
import MoRecruit from "@/pages/Mobile/MoRecruit/MoRecruit";
import MoRecruitInner from "@/pages/Mobile/MoRecruitInner/MoRecruitInner";


// import About from '../components/'
const router = new VueRouter({
    routes: [
        {
            path: '/',
            name: '',
            component: MainPage,
            beforeEnter: (to, from, next) => {
                if (to.path === '/') {
                    // console.log(navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))
                    if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
                        next('/mobile')
                    } else {
                        console.log("222")
                        next()
                    }
                }
            }
        },
        {
            path: '/mobile',
            name: 'MobileIndex',
            component: MobileIndex
        },
        {
            path:'/MobileAbout',
            name:'MobileAbout',
            component:MobileAbout
        },
        {
            path:'/moNews',
            name:'moNews',
            component:MoNewsList
        },
        {
            path:'/MoQualification',
            name:'MoQualification',
            component:MoQualification
        },
        {
            path:'/MoNewsInner',
            name:'MoNewsInner',
            component:MoNewsInner
        },
        {
            path:'/MoRecruit',
            name:'MoRecruit',
            component:MoRecruit
        },
        {
            path:'/MoRecruitInner',
            name:'MoRecruitInner',
            component:MoRecruitInner
        },
        {
            path: '/home',
            component: HomeMain
        },
        {
            path: '/about',
            component: AboutMain
        },
        {
            path: '/news',
            component: NewsList,
            // children:[
            //     {
            //         path:'news',
            //         component:NewsInner
            //     }
            // ]
        },
        {
            path: '/newsInner',
            component: NewsInner
        },
        {
            path: '/someThings',
            component: AboutMain,
        },
        {
            path: '/qualification',
            component: Qualification
        },
        {
            path: '/contactUs',
            component: AboutMain
        },
        {
            path: '/recruit',
            component: Recruit
        }
    ],
    scrollBehavior(to) {
        // savedPosition 的触发是在点击浏览器的前进和后退按钮
        if (to.hash) {
            return {
                selector: to.hash,
                behavior: 'smooth',
                block: 'start'
            }
        } else {
            return {x: 0, y: 0}
        }
    }
})

// router.beforeEach((to, from, next) => {
//     console.log("这是to",to)
//     console.log("这是from",from)
//     // next()
//     if (to.path === '/') {
//         console.log("@@@")
//         // console.log(navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))
//         if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
//             console.log("@!@!@!")
//             next('/mobile')
//         } else {
//             console.log("222")
//             next()
//         }
//     }
// })

export default router