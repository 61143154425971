<template>
  <div id="HomeMain">
<!--    <HeadEr></HeadEr>-->
    <Banner></Banner>
    <BigNews></BigNews>
    <IndexAbout></IndexAbout>
    <BigEvents></BigEvents>
    <OurProductIndex></OurProductIndex>
  </div>
</template>

<script>

import Banner from "@/components/PC/Banner/Banner";
import BigNews from "@/components/PC/BigNews/BigNews";
import IndexAbout from "@/components/PC/IndexAbout/IndexAbout"
import BigEvents from "@/components/PC/BigEvents/BigEvents";
import OurProductIndex from "@/components/PC/OurProductIndex/OurProductIndex";
// import HeadEr from "@/components/Header/Head";

export default {
  name: 'HomeMain',
  components: {
    // HeadEr,
    OurProductIndex,
    BigEvents,
    Banner,
    BigNews,
    IndexAbout,
  }

}
</script>
<style>
#HomeMain{
  position: relative;
}
</style>