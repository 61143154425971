<template>
  <div class="newsContainer clear">
    <ul class="clear">
      <li class="newItem fl" v-for="item in newsList" :key="item.id" :style="{backgroundColor:item.backgroud}">
        <router-link :to="{
          path: '/newsInner',
          query:{
            id:item.id
          }
        }">
          <div class="imgContainer">
            <div class="newsImg">
<!--              <img :src="index === 0 ?baseURL+item.path:baseURL+item.detail_path" :onerror="errorImg"/>-->
              <img :src="baseURL+item.path" :onerror="errorImg"/>
            </div>
            <!--            <div class="newsImg"></div>-->
          </div>
          <div class="newsMain">
            <div class="newDate boxSizing" :class="item.backgroud != '#ffffff' ?'whiteWords':''">{{ item.create_time.slice(0,11) }}</div>
            <div class="newTitle boxSizing" :class="item.backgroud != '#ffffff' ?'whiteWords':''">{{ item.title }}</div>
            <!--            <div class="newText boxSizing"  :class="item.backgroud != '#ffffff' ?'whiteWords':''">-->
            <div class="newText boxSizing" v-html="item.content.replace(/<\/?(img)[^>]*>/gi,'').replace(/<[^>]+>/g,'')" :class="item.backgroud != '#ffffff' ?'whiteWords':''">
            </div>
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from "axios"
import global from "@/config/global";

export default {
  name: "SmallNews",
  data() {
    return {
      newsList: [],
      baseURL:global.BASE_URL,
      errorImg: 'this.src="' + require('../../../assets/errorImg.svg') + '"',
    }
  },
  methods: {
    getNewsList() {
      axios.get('/journalism/queryNewsList').then(
          response => {
            // console.log(response.data)
            this.newsList = response.data
          },
          error => {
            console.log(error.message)
          }
      )
    }
  },
  created() {
    // const baseUrl=global.BASE_URL
    // // const classId=this.$route.query.classId
    // this.data.baseUrl = baseUrl
    // console.log(this.data.baseUrl)
  },
  mounted() {
    this.getNewsList()
  }
}
</script>

<style scoped>
.newsContainer .whiteWords{
  color: #fff!important;
}
.newsContainer {
  width: 1200px;
  margin: 24px auto 0 auto;
}

.newsContainer li:nth-child(2), .newsContainer li:nth-child(3n+2) {
  margin-right: 0;
}

.newsContainer li:first-child .imgContainer {
  width: 100%;
  height: 350px;
  overflow: hidden;
}

.newsContainer li .imgContainer {
  width: 100%;
  height: 181px;
  overflow: hidden;
}

.newsContainer li:first-child .imgContainer .newsImg {
  /*background: url("image/news1.jpg") center center no-repeat;*/
  width: 100%;
  height: 350px;
  overflow: hidden;
  background-size: cover;
}

.newsContainer li .imgContainer .newsImg {
  /*background: url("image/news1.jpg") center center no-repeat;*/
  width: 100%;
  height: 181px;
  overflow: hidden;
  background-size: cover;
}

.newsContainer li:first-child .imgContainer .newsImg img,.newsContainer li .imgContainer .newsImg img{
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.newsContainer li:first-child {
  width: 790px;
  height: 590px;
}
.newsContainer li:first-child .newText{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.newsContainer li .newText{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  line-height: 170%;
}
.newsContainer li:first-child .imgContainer, .newsContainer li:first-child div img {
  display: block;
  width: 100%;
  height: 350px;
  border: 0 none;
  background-color: #333;
  overflow: hidden;
}

.newsContainer li.newItem .newsImg {
  transition: 0.5s;
}

.newsContainer li.newItem:hover .newsImg {
  transform: scale(1.1, 1.1);
}

.newsContainer li.newItem:hover {
  margin-top: -6px;
  cursor: pointer;
}

.newsContainer li {
  width: 383px;
  height: 590px;
  margin-right: 24px;
  margin-bottom: 24px;
  transition: 0.5s;
}

.newsContainer .newDate {
  display: inline-block;
  margin-top: 32px;
  font-size: 14px;
  padding: 8px 26px;
  border: 1px solid;
  border-left: 0 none;
  margin-bottom: 24px;
  color: #333;
}

.newsContainer li:first-child .newTitle{
  -webkit-line-clamp: 1;
}
.newsContainer .newTitle {
  font-size: 24px;
  color: #333;
  margin-bottom: 32px;
  padding: 0 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  min-height:64px;
}
.newsContainer li:first-child .newTitle{
  min-height: auto;
}

.newsContainer .newText {
  font-size: 16px;
  line-height: 150%;
  color: #808080;
  padding: 0 24px;
  font-weight: normal;

}

.newsContainer img {
  display: block;
  width: 100%;
  height: 181px;
  border: 0 none;
  background-color: #333;
}
/*.newsContainer p.MsoNormal img{*/
/*  display: none;*/
/*}*/

</style>