<template>
  <div class="dialog boxSizing clear" @keyup.esc="closeMask">
    <div class="icon fl"><img :src="picture" /></div>
    <div class="iconRight fl">
      <div class="iconRightContainer">
        <div class="titleBig clear">
          <div class="titleBigText fl">{{ title }}</div>
          <div class="close fr" @click="closeMask"></div>
        </div>
        <div class="titleSmall clear">
          <div class="textBig fl">{{text}}</div>
          <a @click="hrefClick" class="link fr" v-if="url != ''&&code==''">前往官网</a>
          <a class="link fr" v-if="url == ''" @mouseover="showEwm" @mouseleave="hideEwm">
            前往打开
            <img class="ewm" v-if="showEwmOrNot" :src="this.baseUrl+code"/>
          </a>
          <a class="link fr" v-if="url != ''&&code!=''" @mouseover="showEwm" @mouseleave="hideEwm" @click="hrefClick">
            <span v-show="showEwmOrNot === false">下载{{title}}</span><span v-show="showEwmOrNot === true">前往{{title}}官网</span>
            <img class="ewm" v-show="showEwmOrNot" :src="this.baseUrl+code"/>
          </a>
        </div>
      </div>
      <p class="dialogText" v-html="content.replace(/<[^>]+>/g,'')"></p>
    </div>
  </div>
</template>

<script>
import global from "@/config/global";
export default {
  name: "DiaLog",
  data(){
    return{
      showEwmOrNot:false,
      baseUrl:global.BASE_URL
    }
  },
  props: {
    showOrNot: {
      type: Boolean,
      default: false
    },
    content: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    text:{
      type:String,
      default:''
    },
    url:{
      type:String,
      default:''
    },
    picture:{
      type:String,
      default:''
    },
    code:{
      type:String,
      default:''
    }
  },
  methods: {
    closeMask() {
      this.$emit('handleChange', false)
    },
    hrefClick(){
      // window.location.href="https://"+this.url;//当前标签页
      // window.location.href=this.url;//当前标签页
      if(this.url.substr(0,7).toLowerCase() == "http://" || this.url.substr(0,8).toLowerCase() == "https://"){
        window.open(this.url)//打开一个新的标签页
      }else{
        window.open("https://"+this.url)
      }
    },
    showEwm(){
      this.showEwmOrNot = true
    },
    hideEwm(){
      this.showEwmOrNot = false
    }

  },
  created(){
    let that = this
    document.onkeydown = function(e){
      let key = e.keyCode;
      if (key == 27){
        that.closeMask()
      }
    }
  },
  mounted() {
    // this.showMask = this.value
  },
}
</script>

<style scoped>
.dialog {
  width: 1040px;
  background-color: #ffffff;
  position: relative;
  padding: 80px;
  margin-left: calc(50% - 520px);
  margin-top: 200px;
}
.icon,.icon img{
  width: 105px;
  height: 105px;
  /*background-color: #ccc;*/
}
.icon img{
  max-height: 105px;
  max-width: 105px;
}
.close{
  width: 26px;
  height: 26px;
  background: url("image/closeDialog.svg") center center no-repeat;
}
.close:hover{
  cursor: pointer;
}
.iconRight{
  width: calc( 100% - 129px);
  margin-left: 24px;
}
.iconRightContainer{
  border-bottom: 1px solid #dbdbdb;
}
.titleBigText{
  font-size: 30px;
  line-height: 30px;
  color: #2A2E2E;
}
.textBig{
  font-size: 20px;
  line-height: 30px;
  margin: 24px 0 20px 0;
  color: #999999;
  font-weight: lighter;
}
.textBig a span{
  transition: 0.5s;
}
.link{
  display: inline-block;
  margin-top: 24px;
  font-size: 16px;
  color: #ff7d22;
  position: relative;
}
.dialogText{
  font-size: 16px;
  line-height: 30px;
  margin-top: 36px;
  color: #5F6464;
}
.dialogText p{
  font-size: 14px!important;
  line-height: 42px!important;
}
.titleSmall a:hover{
  cursor: pointer;
  color: #DF6917;
}
.ewm{
  display: block;
  border: 0 none!important;
  position: absolute;
  /*background-color: red;*/
  width: 200px;
  height: 200px;
  top: -200px;
  right: 0;
}
</style>