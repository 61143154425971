import Vue from 'vue'
import App from './App.vue'
import './../public/common.css'
import './../public/common/font.css'
import global from "@/config/global";

import ElementUi from 'element-ui'
import VueAMap from "vue-amap"
import 'element-ui/lib/theme-chalk/index.css';
import "./../public/common.js"
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper)
// import VideoPlayer from 'vue-video-player'
// // import 'vue-video-player/src/custom-theme.css'
// // import 'video.js/dist/video-js.css'
// require('video.js/dist/video-js.css')
// require('vue-video-player/src/custom-theme.css')
//

// import axios from "axios";

//axios
// import axios from "axios";
// axios.defaults.baseURL = 'https://www.heyoutec.com'
// axios.defaults.baseURL = 'http://10.10.21.177:8080'
// axios.defaults.baseURL=(process.env.NODE_ENV === 'development')?process.env.BASE_API:'';
//vueRouter
import VueRouter from 'vue-router'
import router from './router/index'

Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(ElementUi)
Vue.use(VueAMap)
// Vue.use(VideoPlayer)
Vue.prototype.GLOBAL = global

import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.use(VideoPlayer)

VueAMap.initAMapApiLoader({
    key: "a2f143c700e0499f7beb5af5fb070fdf", // 这里写你申请的高德地图的key
    plugin: [
        "AMap.Autocomplete",
        "AMap.Geocoder",
        "AMap.Geolocation",
        "AMap.Scale",
        "AMap.PlaceSearch",
        "AMap.OverView",
        "AMap.ToolBar",
        "AMap.PolyEditor",
        "AMap.CircleEditor"

    ],
    v: "1.4.15",
    uiVersion: "1.1"
});


new Vue({
    render: h => h(App),
    router: router
}).$mount('#app')
