<template>
  <div style="position: relative;height: 100vh;">
    <swiper
        :options="swiperOption"
    >
      <swiper-slide class="s1 boxSizing">
        <div class="banner-text-box boxSizing">
          <p class="banner-text-big">智慧化引领，科技化驱动</p>
          <p class="banner-text-small">合友科技，旨在助力企业成长，科技化的现代管理模式</p>
        </div>
      </swiper-slide>
      <swiper-slide class="s1 boxSizing s2">
        <div class="banner-text-box boxSizing">
          <p class="banner-text-big">科技驱动项目管理,信息驱动产业升级</p>
          <p class="banner-text-small">建筑行业信息化解决方案服务平台</p>
        </div>
      </swiper-slide>
      <swiper-slide class="s1 boxSizing s3">
        <div class="banner-text-box boxSizing">
          <p class="banner-text-big">权威资质认证，行业专家护航</p>
          <p class="banner-text-small">行业专家深度解析，引领行业发展脉搏</p>
        </div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
    <div class="swiper-button-prev" slot="button-prev"></div>
    <div class="swiper-button-next" slot="button-next"></div>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'

export default {
  name: "MoIndexBanner",
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      on:{
        slideChange: function () {
          console.log(this.activeIndex);
        },
      },
      swiperOption: {
        // slidesPerView: 2,
        // 设置分页器
        pagination: {
          el: '.swiper-pagination',
          type:'progressbar'
          // 设置点击可切换
          // clickable: true
        },
        // 设置前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        // 设置自动轮播
        autoplay: {
          delay: 3000 // 5秒切换一次
        },
        // 设置轮播可循环
        loop: true,
      }
    }
  },
  metaInfo() {
    return {
      meta: [
        {
          name: 'viewport',
          content: 'width=device-width,user-scalable = no,initial-scale = 1.0,maximum-scale = 1.0,minimum-scale =1.0'
        }
      ]
    }
  },
  methods: {},
  mounted() {
  }
}
</script>

<style scoped>

.banner-text-box{
  position: absolute;
  top: calc(50% - 12rem);
}

/*.banner {*/
/*  width: 100%;*/
/*  height: 100vh;*/
/*  background: url("./image/banner1.jpg") center center no-repeat;*/
/*  background-size: cover;*/
/*  padding-top: 23rem;*/
/*}*/

.banner-text-box {
  width: calc(100% - 4.8rem);
  margin: 0 auto;
  color: #fff;
  line-height: 100%;
}

.banner-text-big {
  font-size: 2.8rem;
  font-weight: 500;
  margin-bottom: 1rem;
  line-height: 150%;
  /*width: calc(100% - 4.8rem);*/
}

.banner-text-small {
  font-size: 1.8rem;
  line-height: 180%;
  width: calc(100% - 4.8rem);
}

.swiper-container {
  background-color: #333;
  z-index: -1;
  height: 100vh;
  width: 100%;
  --swiper-theme-color: #fff;
  --swiper-pagination-color: #fff;/* 两种都可以 */
  margin: 0;
  padding:0;
}

.swiper-button-prev:after, .swiper-button-next:after {
  width: 1.2rem;
  height: 1.2rem;
}

.swiper-button-prev, .swiper-button-next {
  position: absolute;
  width: 1.2rem;
  height: 1.2rem;
  top: calc(100% - 18rem);
  /*top: 51rem;*/
  /*bottom: -20rem!important;*/
}

.swiper-button-prev {
  left: 2.4rem;
}

.swiper-button-next {
  left: 12rem;
}

.swiper-button-prev:after {
  content: url("image/arrLeft.svg");
}

.swiper-button-next:after {
  content: url("image/arrRight.svg");
}

.s1 {
  width: 100%;
  background: url("./image/banner1.jpg") center center no-repeat;
  background-size: cover;
  padding: 0 2.4rem 0 2.4rem;
  color: #fff;
}
.s2{
  background: url("./image/banner2.jpg") center center no-repeat;
}
.s3{
  background: url("./image/banner3.jpg") center center no-repeat;
}
.swiper-pagination{
  width: 32.7rem;
  left: 2.4rem;
  top: calc(100% - 14rem);
  height: 0.3rem;
  /*left: 4.4rem;*/
}
.swiper-pagination-progressbar .swiper-pagination-progressbar-fill{
  background: #ff7d22;
}
</style>