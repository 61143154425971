<template>
  <div class="aboutUsBigBox" style="z-index: 9;">
    <p class="title orange">合友概况</p>
    <div class="aboutUsBox clear">
      <div class="aboutLeft fl">
        <div class="row clear">
          <div class="fl imgDiv1"></div>
          <div class="pBox boxSizing fl" v-if="aboutUsInfo">
            <p class="pBoxTitle"><span class="pBoxTitleBig orange">{{aboutUsInfo[0].title.slice(0,1)}}</span>{{aboutUsInfo[0].title.slice(1)}}<span class="pBoxEn">{{aboutUsInfo[0].english_title}}</span></p>
            <ul class="rowTextUl clear">
              <p v-html="aboutUsInfo[0].content" class="textOver3"></p>
<!--              <p>...</p>-->
            </ul>
          </div>
        </div>
        <div class="row clear">
          <div class="pBox boxSizing fl" v-if="aboutUsSmall">
            <p class="pBoxTitle"><span class="pBoxTitleBig orange">{{aboutUsSmall[0].title.slice(0,1)}}</span>{{aboutUsSmall[0].title.slice(1)}}<span class="pBoxEn">{{aboutUsSmall[0].english_title}}</span>
            </p>
            <ul class="rowTextUl2 clear">
              <li v-for="item in aboutUsSmall" :key="item.id" class="fl boxSizing">
                <p class="rowTitle orange bold">{{item.small_title}}</p>
                <p v-html="item.content" class="textOver3"></p>
              </li>
<!--              <p class="fl">...</p>-->
            </ul>
          </div>
          <div class="fl imgDiv2"></div>
        </div>
      </div>
      <div class="aboutRight fr">
        <div class="row clear">
          <div class="fl imgDiv3"></div>
          <div class="pBox boxSizing fl" v-if="aboutUsInfo">
            <p class="pBoxTitle">
              <span class="pBoxTitleBig orange">{{aboutUsInfo[aboutUsInfo.length-1].title.slice(0,1)}}</span>{{aboutUsInfo[aboutUsInfo.length-1].title.slice(1)}}<span class="pBoxEn">{{aboutUsInfo[aboutUsInfo.length-1].english_title}}</span></p>
            <ul class="rowTextUl clear">
              <p v-html="aboutUsInfo[aboutUsInfo.length-1].content" class="textOver5"></p>
<!--              <p>...</p>-->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "IndexAbout",
  data() {
    return {
      aboutUsInfo:'',
      aboutUsSmall:'',
    }
  },
  methods:{
    getAboutInfo() {
      axios.get('/points_img/queryPointsList').then(
          response => {
            // console.log(response.data)
            this.aboutUsInfo = response.data
            let oldArr = response.data
            let newArr = []
            for(let i = 0;i < oldArr.length;i++){
              if(oldArr[i].sort == '2'){
                newArr.push(oldArr[i])
              }
            }
            // console.log(newArr)
            this.aboutUsSmall = newArr.slice(0,2)
            // console.log("11111",this.aboutUsSmall)
          },
          error => {
            console.log(error.message)
          }
      )
    },
  },
  mounted() {
    this.getAboutInfo()
  }
}
</script>

<style scoped>
.aboutUsBigBox {
  width: 100%;
  background-color: #fff;
}

.aboutUsBigBox > p {
  width: 1200px;
  margin: 0 auto;
}

.aboutUsBox {
  width: 1200px;
  margin: 0 auto;
}

.aboutLeft {
  width: 831px;
}
.aboutRight{
  width: 345px;
  margin-left: 24px;
}
.aboutRight .row{
  height: 545px;
}
.aboutRight .row .pBox{
  width: 345px;
  padding: 32px;
}
.row {
  width: 100%;
  height: 260px;
  background-color: #fafafa;
  margin-bottom: 24px;
}

.row .imgDiv1 ,.row .imgDiv2,.row .imgDiv3{
  display: block;
  width: 250px;
  height: 260px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  /*background-color: #ccc;*/
}
.row .imgDiv3{
  width: 345px;
  height: 250px;
}
.row .imgDiv1{
  background: url("image/row1.jpg");
}
.row .imgDiv2{
  background: url("image/row2.jpg");
}
.row .imgDiv3{
  background: url("image/collum.jpg");
}
.rowTextUl p,.rowTextUl2 li p{
  width: 100%;
  /*margin-bottom: 8px;*/
  font-size: 16px;
  line-height: 32px;
}
.rowTextUl2 li:first-child{
  width: calc( 50% - 20px);
  margin-right: 20px;
}
.rowTextUl2 li:nth-child(2){
  padding-left: 20px;
  width: calc( 50% - 20px);
}
.rowTextUl2>p{
  width: 100%;
  /*margin-top: 6px;*/
}
.pBoxTitle {
  font-size: 16px;
  font-style: italic;
  margin-bottom: 16px;
}

.pBoxTitleBig {
  font-size: 32px;
  font-style: italic;
}

.pBoxEn {
  font-family: 'Gill Sans';
  color: #bdbdbd;
  font-size: 16px;
  line-height: 100%;
  display: inline-block;
  margin-left: 8px;
}

.pBox {
  width: 581px;
  padding: 32px 60px 0 60px;
}
</style>