<template>
  <div>
    <MoIndexBanner></MoIndexBanner>
    <MoIndexNews></MoIndexNews>
    <MoIndexAbout></MoIndexAbout>
    <MoIndexPro></MoIndexPro>
  </div>
</template>

<script>
import MoIndexBanner from "@/components/Mobile/MoIndexBanner/MoIndexBanner";
import MoIndexNews from "@/components/Mobile/MoIndexNews/MoIndexNews";
import MoIndexAbout from "@/components/Mobile/MoIndexAbout/MoIndexAbout";
import MoIndexPro from "@/components/Mobile/MoIndexPro/MoIndexPro";
export default {
  name: "MobileIndex",
  components: {MoIndexPro, MoIndexAbout, MoIndexNews, MoIndexBanner},
  metaInfo() {
    return {
      meta: [
        {
          name: 'viewport',
          content: 'width=device-width,user-scalable = no,initial-scale = 1.0,maximum-scale = 1.0,minimum-scale =1.0'
        }
      ]
    }
  },
}
</script>

<style scoped>

</style>