<template>
  <div>
    <div class="moBannerOut moBannerInside boxSizing">
      <p class="moBannerTitle moTitle">合友动态</p>
    </div>
    <MoNews></MoNews>
  </div>
</template>

<script>
import MoNews from "@/components/Mobile/MoNews/MoNews";
export default {
  name: "MoNewsList",
  components: {MoNews}
}
</script>

<style scoped>
.moBannerInside{
  background-image: url("./image/banner.jpg");
  margin-bottom: 3rem;
}
.moBannerTitle{
  margin: 0;
  text-align: left;
  padding-left: 2.4rem;
  color: #fff;
  font-size: 2.8rem;
  font-weight: lighter;
}
</style>