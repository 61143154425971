<template>
  <div class="nav-box" :class="navFixed == true ? 'isFixed' : '' || newNav == true ? 'newStyle' : '' " id="nav-box">
    <div class="nav clear">
      <router-link class="logo fl" to="/home"></router-link>
      <ul class="nav-right fr">
        <li class="fl" v-for="item in nav" :key="item.id">
          <router-link :to="{path:item.url,hash:item.hash}">{{item.name}}</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name:'HeadEr',
  data(){
    return {
      // name:'Head',
      nav:[
        {id:1,name:'首页',url:'/home'},
        {id:2,name:'关于',url:'/about'},
        {id:5,name:'事记',url:'/about',hash:'#pro2'},
        {id:3,name:'产品',url:'/about',hash:'#pro3'},
        {id:4,name:'新闻',url:'/news'},
        {id:6,name:'资质',url:'/qualification'},
        {id:7,name:'联系我们',url:'/contactUs',hash:'#pro5'},
        {id:8,name:'加入我们',url:'/recruit'}
      ],
      url:'../assets/logo.svg',
      navFixed:false,
      newNav:false
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
    // console.log(this.$route)
  },
  methods: {
    handleScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      let offsetTop = document.querySelector(".nav-box").offsetTop
      // console.log(scrollTop)
      if (scrollTop > (offsetTop + 100)) {
        this.navFixed = true
        // console.log(this.navFixed)
      }else{
        this.navFixed = false
      }
    },


  },
  watch:{
    '$route.path':function(newValue,oldValue){
      // console.log(newValue,oldValue)
      if(newValue === '/newsInner'){
        this.newNav = true
      }else{
        this.newNav = false
        console.log(oldValue)
      }
    }
  },
}
</script>

<style>
/*导航*/
.nav-box{
  width:100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 80px;
  transition:0.7s;
  z-index: 11;
  /*background-color: rgba(255,255,255,0.1);*/
}
.isFixed,.newStyle{
  background-color: #fff;
  position: fixed;
  z-index: 999;
}
.isFixed .logo,.newStyle .logo{
  background:url(image/logoOrange.svg) center center no-repeat;
}
.isFixed .nav-right>li>a,.newStyle .nav-right>li>a{
  color: #333;
}
.logo{
  display: block;
  height: 50px;
  width: 150px;
  margin-top: 15px;
  background:url(image/logo.svg) center center no-repeat;
  /*background-color:rgba(255,255,255,0.2)*/
  /*background: url("./public/logo.svg") center center no-repeat;*/
}
.nav{
  width: 1200px;
  margin: 0 auto;
}
.nav-right>li{
  margin-left: 64px;
}
.nav-right>li>a{
  font-size: 16px;
  line-height: 80px;
  color: #fff;
  font-weight: normal;
}
</style>